import { Spring, animated } from "@react-spring/web";
import React from "react";
import { Spinner } from "react-bootstrap";
import { AiFillCheckCircle } from "react-icons/ai";
import { FaPlus } from "react-icons/fa";
import { IoIosArrowDropleftCircle } from "react-icons/io";
import {
  MdDelete,
  MdError,
  MdHideImage,
  MdOutlineAddCircle,
} from "react-icons/md";
import {
  createGeneration,
  createParameter,
  createVariation,
  deleteGeneration,
  deleteVariation,
  generateAll,
  getBoard,
  launchVariation,
  launchVariationWithMask,
  readVariation,
  selectVariation,
  updateBoardCreativity,
  updateParameter,
  updateVariation,
} from "../api";
import { colors } from "../colors";
import { BASE_URL } from "../config";
import { fontSizes } from "../fontSizes";
import { fontWeights } from "../fontWeights";
import Button from "./Button";
import Slider from "./Slider";
import TextInput from "./TextInput";
import ZoneVariation from "./ZoneVariation";

class Image extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  renderImage() {
    const selectedVariation = this.props.image.selected_variation;
    const image_file = selectedVariation.image_files.find(
      (image_file) => image_file.order === selectedVariation.order_selected
    );

    const imgUrl = BASE_URL + "image/" + image_file.path;

    return (
      <img
        style={{ width: "100%", height: "100%", borderRadius: 10 }}
        src={imgUrl}
      ></img>
    );
  }
  render() {
    return (
      <Spring
        from={{ opacity: 0 }}
        to={{ opacity: 1 }}
        delay={this.props.delay}
      >
        {(props) => (
          <animated.div style={{ opacity: props.opacity }}>
            <Spring
              opacity={
                this.props.selectedImage
                  ? this.props.selectedImage.id == this.props.image.id
                    ? 1
                    : 0.5
                  : 1
              }
              width={
                this.props.selectedImage?.id == this.props.image.id
                  ? `${(this.props.image.width / 763) * 120}%`
                  : `${(this.props.image.width / 763) * 100}%`
              }
              height={
                this.props.selectedImage?.id == this.props.image.id
                  ? `${(this.props.image.height / 429) * 120}%`
                  : `${(this.props.image.height / 429) * 100}%`
              }
            >
              {(props2) => (
                <animated.div
                  onClick={() => {
                    this.props.selectImage(this.props.image);
                  }}
                  style={{
                    boxShadow:
                      this.props.selectedImage?.id == this.props.image.id
                        ? "0px 0px 15px 5px rgba(0,0,0,0.1)"
                        : undefined,
                    opacity: props2.opacity,
                    transform: `scale(${props.valueRotate})`,
                    zIndex:
                      this.props.selectedImage?.id == this.props.image.id
                        ? 100
                        : 0,
                    cursor: "pointer",
                    position: "absolute",
                    left: `${(this.props.image.x / 763) * 100}%`,
                    top: `${(this.props.image.y / 429) * 100}%`,
                    width: props2.width,
                    height: props2.height,
                    borderRadius: 10,
                    backgroundColor: colors.blue,
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  {!this.props.image.selected_variation ? (
                    <MdHideImage size={35} color={colors.white} />
                  ) : this.props.image.selected_variation.status !=
                      "complete" &&
                    this.props.image.selected_variation.status != "error" ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        position: "relative",
                      }}
                    >
                      <Spinner variant="light"></Spinner>
                      <div style={{ position: "absolute", bottom: -20 }}>
                        <span
                          style={{
                            fontAlign: "left",
                            fontFamily: "Montserrat",
                            fontWeight: fontWeights.bold,
                            color: colors.brown,
                            fontSize: fontSizes.small,
                          }}
                        >
                          {
                            this.props.image.selected_variation.mj_infos
                              ?.progress
                          }
                          <span style={{ color: colors.white }}>%</span>
                        </span>
                      </div>
                    </div>
                  ) : this.props.image.selected_variation.status === "error" ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        position: "relative",
                      }}
                    >
                      <MdError size={35} color={colors.brown} />
                      <div style={{ position: "absolute", bottom: -20 }}>
                        <span
                          style={{
                            fontAlign: "left",
                            fontFamily: "Montserrat",
                            fontWeight: fontWeights.bold,
                            color: colors.brown,
                            fontSize: fontSizes.small,
                          }}
                        >
                          Erreur
                        </span>
                      </div>
                    </div>
                  ) : (
                    this.renderImage()
                  )}
                </animated.div>
              )}
            </Spring>
          </animated.div>
        )}
      </Spring>
    );
  }
}
class MoodBoard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      heightFactor: 0.6,
    };
  }

  render() {
    const images = this.props.board.images;

    return (
      <div
        onMouseEnter={() => this.setState({ hover: true })}
        onMouseLeave={() => this.setState({ hover: false })}
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          position: "relative",
        }}
      >
        {images.map((image, index) => (
          <Image
            selectedImage={this.props.selectedImage}
            delay={index * 100}
            selectImage={this.props.selectImage}
            key={index}
            image={image}
          ></Image>
        ))}
      </div>
    );
  }
}

class ImageFileViz extends React.Component {
  constructor(props) {
    super(props);
    this.state = { selectedImageFile: null };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedVariation !== this.props.selectedVariation) {
      this.setState({ selectedImageFile: null });
    }
  }

  render() {
    return (
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "flex-start",
          alignContent: "flex-start",
          justifyContent: "flex-start",
          justifyItems: "flex-start",
          height: "100%",
          width: "70%",
        }}
      >
        {this.props.selectedVariation.image_files.map((image, index) => {
          const url = BASE_URL + "image/" + image.path;

          return (
            <Spring
              width={
                this.state.selectedImageFile == null
                  ? "50%"
                  : this.state.selectedImageFile.id == image.id
                  ? "100%"
                  : "0%"
              }
              height={
                this.state.selectedImageFile == null
                  ? "50%"
                  : this.state.selectedImageFile.id == image.id
                  ? "100%"
                  : "0%"
              }
              opacity={
                this.state.selectedImageFile
                  ? this.state.selectedImageFile?.id == image.id
                    ? 1
                    : 0
                  : 1
              }
            >
              {(props) => (
                <animated.div
                  onMouseEnter={() =>
                    this.setState({ ["hover" + image.id]: true })
                  }
                  onMouseLeave={() =>
                    this.setState({ ["hover" + image.id]: false })
                  }
                  onClick={() => {
                    if (this.state.selectedImageFile == null)
                      this.setState({ selectedImageFile: image });
                  }}
                  className="pe-1 pb-1"
                  key={index}
                  style={{
                    ...props,
                    position: "relative",
                  }}
                >
                  <img
                    src={url}
                    style={{
                      borderWidth:
                        this.props.selectedVariation.selected &&
                        this.props.selectedVariation.order_selected ==
                          image.order
                          ? 2
                          : 0,
                      borderRadius: 10,
                      cursor: "pointer",
                      borderStyle: "solid",
                      borderColor: colors.brown,
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                    }}
                  ></img>
                  {this.props.selectedVariation.selected &&
                    this.props.selectedVariation.order_selected ==
                      image.order && (
                      <AiFillCheckCircle
                        color={colors.brown}
                        style={{
                          position: "absolute",
                          top: 10,
                          right: 10,
                          zIndex: 100,
                        }}
                      ></AiFillCheckCircle>
                    )}
                  {this.state.selectedImageFile?.id == image.id && (
                    <Spring opacity={this.state["hover" + image.id] ? 1 : 0}>
                      {(props) => (
                        <>
                          <animated.div
                            style={{
                              ...props,
                              position: "absolute",
                              top: 0,
                              right: 0,
                              height: "100%",
                              width: "100%",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "space-around",
                              alignItems: "center",
                            }}
                          >
                            <div
                              onClick={() => {
                                this.setState({ selectedImageFile: null });
                              }}
                              style={{
                                position: "absolute",
                                left: 10,
                                top: 10,
                                cursor: "pointer",
                              }}
                            >
                              <IoIosArrowDropleftCircle
                                size={20}
                                color={colors.brown}
                              ></IoIosArrowDropleftCircle>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <div className="mb-1">
                                <Button
                                  enabled={this.props.variationEnabled}
                                  onClick={() =>
                                    this.props.launchSimpleVariation(
                                      this.state.selectedImageFile.order
                                    )
                                  }
                                  title={"Variation simple"}
                                ></Button>
                              </div>
                              {/* <div className="mb-1">
                                <Button
                                  enabled={this.props.variationEnabled}
                                  onClick={() =>
                                    this.props.createNewComplexVariation(
                                      this.state.selectedImageFile.order,
                                      "complex_variation"
                                    )
                                  }
                                  title={"Variation complexe"}
                                ></Button>
                              </div> */}
                              <div className="mb-1">
                                <Button
                                  onClick={() =>
                                    this.props.createNewComplexVariation(
                                      this.state.selectedImageFile.order,
                                      "zone_variation"
                                    )
                                  }
                                  enabled={this.props.variationEnabled}
                                  title={"Variation de zone"}
                                ></Button>
                              </div>
                            </div>
                            <Button
                              onClick={() =>
                                this.props.selectVariation(
                                  this.props.selectedVariation,
                                  this.state.selectedImageFile.order
                                )
                              }
                              title={"Sélectionner"}
                            >
                              {" "}
                            </Button>
                          </animated.div>
                        </>
                      )}
                    </Spring>
                  )}
                </animated.div>
              )}
            </Spring>
          );
        })}
      </div>
    );
  }
}

class AdjustParameters extends React.Component {
  constructor(props) {
    super(props);
    this.state = { loading: true, variation: null };
  }

  componentDidMount() {
    this.loadParameters();
  }

  updateVariation = (key, value) => {
    try {
      const variation = this.state.variation;
      variation[key] = value;

      updateVariation(this.props.variationId, variation);
    } catch (error) {
      console.log(error);
    }
  };

  addParameter = async (parameterGroup, value) => {
    console.log("yo", parameterGroup);

    try {
      const parameter = {
        value: value,
        name: value,
        weight: 1,
        activated: true,
      };
      const newParameter = await createParameter(parameterGroup.id, parameter);

      parameterGroup.parameters.push(newParameter);

      this.setState((prevState) => ({
        ...prevState,
        variation: {
          ...prevState.variation,
          parameter_groups: prevState.variation.parameter_groups.map(
            (parameter_group) =>
              parameter_group.id == parameterGroup.id
                ? parameterGroup
                : parameter_group
          ),
        },
      }));
    } catch (error) {
      console.error(
        `Error creating parameter for parameter group with ID ${parameterGroup.id}:`,
        error
      );
    }
  };

  selectParameter = async (parameter) => {
    try {
      const data = {
        activated: !parameter.activated,
      };

      const newParameter = await updateParameter(parameter.id, data);
      console.log("newParameter", newParameter);
      const newVariation = {
        ...this.state.variation,
        parameter_groups: this.state.variation.parameter_groups.map(
          (parameter_group) =>
            parameter_group.id == parameter.parameter_group_id
              ? {
                  ...parameter_group,
                  parameters: parameter_group.parameters.map((parameter) =>
                    parameter.id == newParameter.id ? newParameter : parameter
                  ),
                }
              : parameter_group
        ),
      };
      this.setState((prevState) => ({
        ...prevState,
        variation: newVariation,
      }));
    } catch (error) {
      console.error("Error:", error);
    }
  };

  updateParameter = async (parameterId, weight) => {
    try {
      const parameter = {
        weight: weight,
      };

      await updateParameter(parameterId, parameter);
    } catch (error) {
      console.log(error);
    }
  };

  loadParameters = async () => {
    try {
      this.setState({ loading: true });
      const variation = await readVariation(this.props.variationId);
      this.setState({ variation, loading: false });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  render() {
    if (this.state.loading) return <Spinner></Spinner>;
    return (
      <div
        className="px-2 py-1"
        style={{
          display: "flex",

          height: "100%",
          width: "100%",

          flexDirection: "column",
          overflow: "auto",
        }}
      >
        <span
          style={{
            fontAlign: "left",
            fontFamily: "Montserrat",
            fontWeight: fontWeights.bold,
            color: colors.lightBlue,
            fontSize: fontSizes.normal,
            textAlign: "left",
          }}
        >
          Sujet
        </span>
        <div
          className="mt-1"
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <span
            style={{
              fontAlign: "left",
              fontFamily: "Montserrat",
              fontWeight: fontWeights.bold,
              color: colors.white,
              fontSize: fontSizes.small,
              textAlign: "left",
            }}
          >
            Image d'inspiration
          </span>
          <Slider
            onSliderStop={this.updateVariation.bind(
              this,
              "subject_inspiration_image_weight"
            )}
            height={12}
            min={0}
            max={1}
            width={100}
            defaultValue={this.state.variation.subject_inspiration_image_weight}
            step={0.1}
          ></Slider>
        </div>
        <div
          className="p-2 mt-1"
          style={{
            display: "flex",
            width: "100%",
            borderRadius: 10,
            borderColor: colors.lightBlue,
            borderWidth: 1,
            borderStyle: "solid",
            overflowWrap: "break-word",
          }}
        >
          <textarea
            onBlur={this.updateVariation.bind(
              this,
              "subject",
              this.state.variation.subject_french
            )}
            style={{
              width: "100%",
              backgroundColor: colors.darkBlue,
              borderWidth: 0,
              color: colors.white,
              fontFamily: "Montserrat",
              fontSize: fontSizes.small,
              whiteSpace: "normal",
              wordWrap: "break-word",
            }}
            defaultValue={this.state.variation.subject_french}
            value={this.state.variation.subject_french}
            onChange={(event) =>
              this.setState((prevState) => ({
                ...prevState,
                variation: {
                  ...prevState.variation,
                  subject_french: event.target.value,
                },
              }))
            }
          />
        </div>
        <span
          className="mt-2"
          style={{
            fontAlign: "left",
            fontFamily: "Montserrat",
            fontWeight: fontWeights.bold,
            color: colors.lightBlue,
            fontSize: fontSizes.normal,
            textAlign: "left",
          }}
        >
          Couleurs
        </span>
        <div
          className="mt-1"
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            alignContent: "flex-start",
            flexWrap: "wrap",
          }}
        >
          {this.state.variation.parameter_groups.filter(
            (parameter_group) => parameter_group.type == "colors"
          ).length > 0
            ? this.state.variation.parameter_groups
                .filter(
                  (parameter_group) => parameter_group.type == "colors"
                )[0]
                .parameters.map((parameter, index) => (
                  <div
                    className="me-1"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <div
                      className="mb-1"
                      style={{
                        width: 75,
                        height: 65,
                        borderRadius: 10,
                        backgroundColor: parameter.value,
                      }}
                    ></div>
                    <Slider
                      onSliderStop={this.updateParameter.bind(
                        this,
                        parameter.id
                      )}
                      height={12}
                      min={0}
                      max={3}
                      width={70}
                      defaultValue={parameter.weight}
                      step={1}
                    ></Slider>
                  </div>
                ))
            : null}
        </div>

        <span
          className="mt-2"
          style={{
            fontAlign: "left",
            fontFamily: "Montserrat",
            fontWeight: fontWeights.bold,
            color: colors.lightBlue,
            fontSize: fontSizes.normal,
            textAlign: "left",
          }}
        >
          Mots clés
        </span>
        <div
          className="mt-1"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
          }}
        >
          <div
            className="me-1 mb-1"
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <div
              className="me-1"
              style={{
                display: "flex",
                width: "50%",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <span
                style={{
                  fontAlign: "left",
                  fontFamily: "Montserrat",
                  fontWeight: fontWeights.medium,
                  color: colors.white,
                  fontSize: fontSizes.small,
                  textAlign: "left",
                }}
              >
                Dénomination
              </span>
            </div>
            <div
              style={{
                display: "flex",
                width: "50%",
                justifyContent: "flex-start",

                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <span
                style={{
                  fontAlign: "left",
                  fontFamily: "Montserrat",
                  fontWeight: fontWeights.medium,
                  color: colors.white,
                  fontSize: fontSizes.small,
                  textAlign: "left",
                }}
              >
                Poids
              </span>
            </div>
          </div>
          {this.state.variation.parameter_groups
            .filter(
              (parameter_group) =>
                parameter_group.type != "colors" &&
                parameter_group.type != "exclude"
            )
            .map((parameter_group) =>
              parameter_group.parameters.map((parameter, index) => (
                <Spring
                  to={{
                    backgroundColor:
                      parameter.activated ||
                      this.state[parameter.id + "parameter"]
                        ? colors.brown
                        : "transparent",
                    color: colors.white,
                    borderColor:
                      parameter.activated ||
                      this.state[parameter.id + "parameter"]
                        ? colors.brown
                        : colors.white,
                  }}
                >
                  {(props) => (
                    <div
                      className="mb-1"
                      style={{
                        display: "flex",
                        width: "100%",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          width: "50%",
                          alignItems: "center",
                        }}
                      >
                        <animated.div
                          onMouseEnter={() =>
                            this.setState({
                              [parameter.id + "parameter"]: true,
                            })
                          }
                          onMouseLeave={() =>
                            this.setState({
                              [parameter.id + "parameter"]: false,
                            })
                          }
                          className="px-2 me-1"
                          style={{
                            display: "flex",
                            backgroundColor: props.backgroundColor,
                            borderColor: props.borderColor,
                            borderRadius: 10,
                            borderStyle: "solid",
                            borderWidth: 1,
                            borderRadius: 10,
                          }}
                        >
                          <span
                            style={{
                              textAlign: "left",
                              fontFamily: "Montserrat",
                              fontWeight: parameter.activated
                                ? fontWeights.bold
                                : fontWeights.medium,
                              color: colors.white,
                              fontSize: fontSizes.small,
                            }}
                          >
                            {parameter.name}
                          </span>
                        </animated.div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          width: "50%",
                          justifyContent: "flex-start",

                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        {parameter.activated && (
                          <Slider
                            onSliderStop={this.updateParameter.bind(
                              this,
                              parameter.id
                            )}
                            height={12}
                            min={0}
                            max={3}
                            width={100}
                            defaultValue={parameter.weight}
                            step={1}
                          ></Slider>
                        )}
                      </div>
                    </div>
                  )}
                </Spring>
              ))
            )}
          <span
            className="mt-2"
            style={{
              fontAlign: "left",
              fontFamily: "Montserrat",
              fontWeight: fontWeights.bold,
              color: colors.lightBlue,
              fontSize: fontSizes.normal,
              textAlign: "left",
            }}
          >
            Exclure
          </span>
          <div
            className="mt-1"
            style={{
              display: "flex",

              justifyContent: "flex-start",
              alignItems: "flex-start",
              alignContent: "flex-start",
              flexWrap: "wrap",
            }}
          >
            {this.state.variation.parameter_groups
              .find((parameter_group) => parameter_group.type == "exclude")
              ?.parameters.map((parameter, index) => (
                <Spring
                  to={{
                    backgroundColor:
                      parameter.activated ||
                      this.state[parameter.id + "parameter"]
                        ? colors.brown
                        : "transparent",
                    color: colors.white,
                    borderColor:
                      parameter.activated ||
                      this.state[parameter.id + "parameter"]
                        ? colors.brown
                        : colors.white,
                  }}
                >
                  {(props) => (
                    <animated.div
                      onClick={() => this.selectParameter(parameter)}
                      onMouseEnter={() =>
                        this.setState({
                          [parameter.id + "parameter"]: true,
                        })
                      }
                      onMouseLeave={() =>
                        this.setState({
                          [parameter.id + "parameter"]: false,
                        })
                      }
                      className="px-2 me-1 mb-1"
                      style={{
                        cursor: "pointer",
                        backgroundColor: props.backgroundColor,
                        borderColor: props.borderColor,
                        borderRadius: 10,
                        borderStyle: "solid",
                        borderWidth: 1,
                      }}
                    >
                      <span
                        style={{
                          fontAlign: "left",
                          fontFamily: "Montserrat",
                          fontWeight: parameter.activated
                            ? fontWeights.bold
                            : fontWeights.medium,
                          color: colors.white,
                          fontSize: fontSizes.small,
                          textAlign: "left",
                        }}
                      >
                        {parameter.name}
                      </span>
                    </animated.div>
                  )}
                </Spring>
              ))}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                width: "100%",
              }}
            >
              <FaPlus
                className="me-2"
                size={fontSizes.small}
                color={colors.brown}
              ></FaPlus>
              <TextInput
                onKeyPress={async (e) => {
                  if (e.key === "Enter") {
                    await this.addParameter(
                      this.state.variation.parameter_groups.find(
                        (parameter_group) => parameter_group.type == "exclude"
                      ),
                      this.state.newExlude
                    );
                    this.setState({ newExlude: "" });
                  }
                }}
                onChange={(e) => this.setState({ newExlude: e.target.value })}
                value={this.state.newExlude}
                placeholder={"Mot clé à exclure"}
              ></TextInput>
            </div>
          </div>
          <div
            className="mt-3"
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              onClick={() => this.props.launchComplexVariation()}
              title={"Lancer"}
            ></Button>
          </div>
        </div>
      </div>
    );
  }
}

class Settings extends React.Component {
  constructor(props) {
    super(props);
    this.state = { display: "show" };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.imageSelected?.id !== this.props.imageSelected?.id) {
      if (!this.props.imageSelected) {
        return;
      }
      const selectedVariationId =
        this.props.imageSelected?.selected_variation?.id;
      const selectedGenerationId =
        this.props.imageSelected?.selected_variation?.generation_id;

      const selectedGeneration = this.props.imageSelected.generations.find(
        (generation) => generation.id === selectedGenerationId
      );
      const selectedVariation = selectedGeneration?.variations.find(
        (variation) => variation.id === selectedVariationId
      );
      this.setState({
        selectedGeneration: selectedGeneration,
        selectedVariation: selectedVariation,
      });
    } else {
      if (
        prevProps.imageSelected !== this.props.imageSelected &&
        this.state.selectedGeneration &&
        this.state.selectedVariation
      ) {
        const selectedGeneration = this.props.imageSelected.generations.find(
          (generation) => generation.id === this.state.selectedGeneration.id
        );
        const selectedVariation = selectedGeneration.variations.find(
          (variation) => variation.id === this.state.selectedVariation.id
        );
        this.setState({
          selectedGeneration: selectedGeneration,
          selectedVariation: selectedVariation,
        });
      }
    }
  }

  launchSimpleVariation = async (order) => {
    console.log(
      this.state.selectedGeneration.id,
      this.props.imageSelected.id,
      this.state.selectedVariation.id
    );
    const variation = await this.props.launchSimpleVariation(
      this.state.selectedGeneration.id,
      this.props.imageSelected.id,
      this.state.selectedVariation.id,
      order
    );
    this.setState({ selectedVariation: variation });
  };

  launchComplexVariation = async (mask = null) => {
    const variation = await this.props.launchComplexVariation(
      this.state.selectedGeneration.id,
      this.props.imageSelected.id,
      this.state.selectedVariation.id,
      mask
    );
    this.setState({ selectedVariation: variation });
  };

  createNewComplexVariation = async (order, type) => {
    console.log(
      this.state.selectedGeneration.id,
      this.props.imageSelected.id,
      this.state.selectedVariation.id
    );
    const variation = await this.props.createNewComplexVariation(
      this.state.selectedGeneration.id,
      this.props.imageSelected.id,
      this.state.selectedVariation.id,
      order,
      type
    );
    this.setState({ selectedVariation: variation });
  };

  deleteVariation = async () => {
    await this.props.deleteVariation(this.state.selectedVariation.id);
    this.setState({ selectedVariation: null });
  };

  renderDisplay() {
    if (!this.state.selectedVariation)
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <span
              style={{
                fontFamily: "Montserrat",
                fontWeight: fontWeights.bold,
                color: colors.lightBlue,
                fontSize: fontSizes.normal,
              }}
            >
              Aucune variation sélectionnée
            </span>
          </div>
        </div>
      );
    if (this.state.display == "show") {
      if (this.state.selectedVariation.status == "complete") {
        return (
          <div
            style={{
              display: "flex",
              position: "relative",
              height: "100%",
              width: "100%",
            }}
          >
            <div
              onClick={() => this.deleteVariation()}
              style={{
                cursor: "pointer",
                width: 20,
                height: 20,
                position: "absolute",
                right: 0,
                top: -20,
              }}
            >
              <MdDelete size={20} color={colors.brown}></MdDelete>
            </div>
            <ImageFileViz
              variationEnabled={
                this.state.selectedVariation ===
                this.state.selectedGeneration.variations[
                  this.state.selectedGeneration.variations.length - 1
                ]
              }
              launchComplexVariation={this.launchComplexVariation.bind(this)}
              createNewComplexVariation={this.createNewComplexVariation.bind(
                this
              )}
              launchSimpleVariation={this.launchSimpleVariation.bind(this)}
              selectVariation={this.props.selectVariation}
              selectedVariation={this.state.selectedVariation}
            ></ImageFileViz>
            <div
              className="px-2 py-1"
              style={{
                display: "flex",

                height: "100%",
                width: "30%",
                borderLeftWidth: 1,
                borderLeftColor: colors.white,
                borderLeftStyle: "solid",
                flexDirection: "column",
                overflow: "auto",
              }}
            >
              <span
                style={{
                  fontAlign: "left",
                  fontFamily: "Montserrat",
                  fontWeight: fontWeights.bold,
                  color: colors.lightBlue,
                  fontSize: fontSizes.normal,
                  textAlign: "left",
                }}
              >
                Sujet
              </span>
              <div
                className="mt-1"
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <span
                  style={{
                    fontAlign: "left",
                    fontFamily: "Montserrat",
                    fontWeight: fontWeights.bold,
                    color: colors.white,
                    fontSize: fontSizes.small,
                    textAlign: "left",
                  }}
                >
                  Image d'inspiration
                </span>
                <span
                  style={{
                    fontAlign: "left",
                    fontFamily: "Montserrat",
                    fontWeight: fontWeights.medium,
                    color: colors.brown,
                    fontSize: fontSizes.small,
                    textAlign: "left",
                  }}
                >
                  {
                    this.state.selectedVariation
                      .subject_inspiration_image_weight
                  }
                </span>
              </div>
              <span
                className="mt-1"
                style={{
                  fontAlign: "left",
                  fontFamily: "Montserrat",
                  fontWeight: fontWeights.medium,
                  color: colors.white,
                  fontSize: fontSizes.small,
                  textAlign: "left",
                }}
              >
                {this.state.selectedVariation.subject_french}
              </span>
              <span
                className="mt-2"
                style={{
                  fontAlign: "left",
                  fontFamily: "Montserrat",
                  fontWeight: fontWeights.bold,
                  color: colors.lightBlue,
                  fontSize: fontSizes.normal,
                  textAlign: "left",
                }}
              >
                Couleurs
              </span>
              <div
                className="mt-1"
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  alignContent: "flex-start",
                  flexWrap: "wrap",
                }}
              >
                {this.state.selectedVariation.parameter_groups.filter(
                  (parameter_group) => parameter_group.type == "colors"
                ).length > 0
                  ? this.state.selectedVariation.parameter_groups
                      .filter(
                        (parameter_group) => parameter_group.type == "colors"
                      )[0]
                      .parameters.map((parameter, index) => (
                        <div
                          className="me-1"
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <div
                            className="mb-1"
                            style={{
                              width: 50,
                              height: 40,
                              borderRadius: 10,
                              backgroundColor: parameter.value,
                            }}
                          ></div>
                          <div
                            className="mb-2"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              backgroundColor: colors.lightBlue,
                              width: 35,
                              height: fontSizes.small + 2,
                              borderRadius: 10,
                            }}
                          >
                            <span
                              style={{
                                fontFamily: "Montserrat",
                                fontWeight: fontWeights.bold,
                                fontSize: fontSizes.small,
                                color: colors.white,
                              }}
                            >
                              {parameter.weight}
                            </span>
                          </div>
                        </div>
                      ))
                  : null}
              </div>

              <span
                className="mt-2"
                style={{
                  fontAlign: "left",
                  fontFamily: "Montserrat",
                  fontWeight: fontWeights.bold,
                  color: colors.lightBlue,
                  fontSize: fontSizes.normal,
                  textAlign: "left",
                }}
              >
                Mots clés
              </span>
              <div
                className="mt-1"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                }}
              >
                <div
                  className="me-1"
                  style={{
                    display: "flex",
                    width: "100%",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <div
                    className="me-1"
                    style={{
                      display: "flex",
                      width: "50%",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <span
                      style={{
                        fontFamily: "Montserrat",
                        fontWeight: fontWeights.medium,
                        color: colors.white,
                        fontSize: fontSizes.small,
                        textAlign: "left",
                      }}
                    >
                      Dénomination
                    </span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      width: "50%",
                      justifyContent: "flex-start",

                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <span
                      style={{
                        fontAlign: "left",
                        fontFamily: "Montserrat",
                        fontWeight: fontWeights.medium,
                        color: colors.white,
                        fontSize: fontSizes.small,
                        textAlign: "left",
                      }}
                    >
                      Poids
                    </span>
                  </div>
                </div>
                {this.state.selectedVariation.parameter_groups
                  .filter((parameter_group) => parameter_group.type != "colors")
                  .map((parameter_group) =>
                    parameter_group.parameters.map((parameter, index) => (
                      <div
                        className="mb-1"
                        s
                        style={{
                          display: "flex",
                          width: "100%",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <div
                          className="me-1"
                          style={{
                            display: "flex",
                            width: "50%",
                            alignItems: "center",
                          }}
                        >
                          <div
                            className="px-2 me-1"
                            style={{
                              backgroundColor: colors.brown,
                              display: "flex",
                              borderRadius: 10,
                            }}
                          >
                            <span
                              style={{
                                fontFamily: "Montserrat",
                                fontWeight: fontWeights.bold,
                                color: colors.white,
                                fontSize: fontSizes.small,
                                textAlign: "left",
                              }}
                            >
                              {parameter.name}
                            </span>
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            width: "50%",
                            justifyContent: "flex-start",

                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <span
                            style={{
                              fontAlign: "left",
                              fontFamily: "Montserrat",
                              fontWeight: fontWeights.bold,
                              color: colors.lightBlue,
                              fontSize: fontSizes.small,
                              textAlign: "left",
                            }}
                          >
                            {parameter.weight}
                          </span>
                        </div>
                      </div>
                    ))
                  )}
              </div>
            </div>
          </div>
        );
      }
      if (
        (this.state.selectedVariation.status == "pending" &&
          this.state.selectedVariation.type == "simple_variation") ||
        this.state.selectedVariation.status == "in_progress"
      ) {
        return (
          <div
            style={{
              display: "flex",
              width: "100%",
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 10,
              position: "relative",
            }}
          >
            <div
              onClick={() => this.deleteVariation()}
              style={{
                cursor: "pointer",
                width: 20,
                height: 20,
                position: "absolute",
                right: 0,
                top: -20,
              }}
            >
              <MdDelete size={20} color={colors.brown}></MdDelete>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
              }}
            >
              <Spinner variant="light"></Spinner>
              <div style={{ position: "absolute", bottom: -20 }}>
                <span
                  style={{
                    fontAlign: "left",
                    fontFamily: "Montserrat",
                    fontWeight: fontWeights.bold,
                    color: colors.brown,
                    fontSize: fontSizes.small,
                  }}
                >
                  {this.state.selectedVariation?.mj_infos?.progress}
                  <span style={{ color: colors.white }}>%</span>
                </span>
              </div>
            </div>
          </div>
        );
      }
      if (
        (this.state.selectedVariation.variation_type == "complex_variation" ||
          this.state.selectedVariation.variation_type == "original") &&
        this.state.selectedVariation.status == "pending"
      ) {
        let imageToDisplay = null;
        let url = null;

        if (this.state.selectedVariation.from_variation_id) {
          imageToDisplay = this.state.selectedGeneration.variations
            .find(
              (variation) =>
                variation.id === this.state.selectedVariation.from_variation_id
            )
            .image_files?.find(
              (image_file) =>
                image_file.order ===
                this.state.selectedVariation.from_variation_order
            );

          url = BASE_URL + "image/" + imageToDisplay.path;
        }
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "row",

              height: "100%",
              width: "100%",
              position: "relative",
            }}
          >
            <div
              onClick={() => this.deleteVariation()}
              style={{
                cursor: "pointer",
                width: 20,
                height: 20,
                position: "absolute",
                right: 0,
                top: -20,
              }}
            >
              <MdDelete size={20} color={colors.brown}></MdDelete>
            </div>
            {imageToDisplay && (
              <div
                className="p-2"
                style={{
                  display: "flex",
                  flexDirection: "row",

                  height: "100%",
                  width: "50%",
                  borderRightWidth: 1,
                  borderRightColor: colors.white,
                  borderRightStyle: "solid",
                }}
              >
                <img
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                  }}
                  src={url}
                ></img>
              </div>
            )}

            <div
              className="p-2"
              style={{
                display: "flex",
                flexDirection: "row",

                height: "100%",
                width: imageToDisplay ? "50%" : "100%",
              }}
            >
              <AdjustParameters
                launchComplexVariation={this.launchComplexVariation.bind(this)}
                variationId={this.state.selectedVariation.id}
              ></AdjustParameters>
            </div>
          </div>
        );
      }

      if (
        this.state.selectedVariation.variation_type == "zone_variation" &&
        this.state.selectedVariation.status == "pending"
      ) {
        let imageToDisplay = null;
        let url = null;

        if (this.state.selectedVariation.from_variation_id) {
          imageToDisplay = this.state.selectedGeneration.variations
            .find(
              (variation) =>
                variation.id === this.state.selectedVariation.from_variation_id
            )
            .image_files?.find(
              (image_file) =>
                image_file.order ===
                this.state.selectedVariation.from_variation_order
            );

          url = BASE_URL + "image/" + imageToDisplay.path;

          return (
            <ZoneVariation
              deleteVariation={this.deleteVariation.bind(this)}
              variationId={this.state.selectedVariation.id}
              launchComplexVariation={this.launchComplexVariation.bind(this)}
              key={imageToDisplay.id}
              url={url}
              imageFileId={imageToDisplay.id}
            ></ZoneVariation>
          );
        }
      }
    }

    if (this.state.selectedVariation.status == "error") {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "row",

            height: "100%",
            justifyContent: "center",
            alignItems: "center",

            width: "100%",
            position: "relative",
          }}
        >
          <div
            onClick={() => this.deleteVariation()}
            style={{
              cursor: "pointer",
              width: 20,
              height: 20,
              position: "absolute",
              right: 0,
              top: -20,
            }}
          >
            <MdDelete size={20} color={colors.brown}></MdDelete>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <MdError size={36} color={colors.brown}></MdError>
            <span
              style={{
                fontAlign: "left",
                fontFamily: "Montserrat",
                fontWeight: fontWeights,
                color: colors.white,
                fontSize: fontSizes.medium,
              }}
            >
              Une erreur est survenue lors de la génération.
            </span>
          </div>
        </div>
      );
    }
    if (this.state.display == "add") {
      return <div></div>;
    }
  }

  createNewGeneraion = async () => {
    const generation = await this.props.createNewGeneration(
      this.props.imageSelected.id
    );
    console.log("generation", generation);
    this.setState({
      selectedGeneration: generation,
      selectedVariation: generation.variations[0],
    });
  };

  deleteGeneration = async (generationId) => {
    try {
      await this.props.deleteGeneration(generationId);
      this.setState({ selectedGeneration: null, selectedVariation: null });
    } catch (error) {
      console.log(error);
    }
  };

  selectGeneration = (generation) => {
    const variations = generation.variations.sort(
      (a, b) => new Date(b.created_at) - new Date(a.created_at)
    );
    for (let i = 0; i < variations.length; i++) {
      if (variations[i].selected) {
        this.setState({
          selectedGeneration: generation,
          selectedVariation: variations[i],
        });
        return;
      }
    }

    this.setState({
      selectedGeneration: generation,
      selectedVariation: variations[0],
    });
  };

  render() {
    if (!this.props.board || !this.props.imageSelected)
      return (
        <div
          style={{
            display: "flex",
            width: "100%",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            className="p-4"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: colors.blue,
              height: "40%",
              borderRadius: 10,
            }}
          >
            <MdHideImage size={36} color={colors.white}></MdHideImage>

            <span
              className="mt-2"
              style={{
                fontAlign: "left",
                fontFamily: "Montserrat",
                fontWeight: fontWeights,
                color: colors.white,
                fontSize: fontSizes.medium,
              }}
            >
              Aucune image sélectionnée.
            </span>
          </div>
        </div>
      );

    const imageSelected = this.props.board.images.find(
      (image) => image.id === this.props.imageSelected.id
    );

    return (
      <div
        style={{
          display: "flex",
          maxHeight: "100%",
          width: "100%",
          height: "100%",
        }}
      >
        <div
          className="ps-4 p-3 pe-4"
          style={{
            display: "flex",
            maxHeight: "100%",
            backgroundColor: colors.blue,
            borderRadius: 10,
            width: "20%",
            height: "100%",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "space-between",
            overflow: "auto",
          }}
        >
          <div style={{ width: "100%" }}>
            {imageSelected.generations.map((generation, index) => (
              <div
                className="mb-2"
                style={{
                  cursor: "pointer",
                  display: "flex",
                  flexDirection: "row",
                  position: "relative",
                  alignItems: "center",
                  width: "100%",
                  justifyContent: "space-between",
                }}
                onClick={() => this.selectGeneration(generation)}
              >
                {this.state.selectedGeneration &&
                  this.state.selectedGeneration.id === generation.id && (
                    <div
                      style={{
                        position: "absolute",
                        width: 10,
                        height: 10,
                        left: -15,
                        borderRadius: 20 / 2,
                        backgroundColor: colors.brown,
                      }}
                    ></div>
                  )}
                <span
                  style={{
                    textAlign: "left",
                    fontFamily: "Montserrat",
                    fontWeight:
                      this.state.selectedGeneration?.id === generation.id
                        ? fontWeights.bold
                        : fontWeights.medium,
                    color: colors.white,
                    fontSize: fontSizes.medium,
                  }}
                >
                  {generation.name}
                </span>
                {this.state.selectedGeneration &&
                  this.state.selectedGeneration.id === generation.id && (
                    <MdDelete
                      onClick={() => this.deleteGeneration(generation.id)}
                      size={20}
                      color={colors.brown}
                    ></MdDelete>
                  )}
              </div>
            ))}
          </div>
          <div
            className="mb-2"
            onClick={() => this.createNewGeneraion()}
            style={{
              cursor: "pointer",
              display: "flex",
              flexDirection: "row",
              position: "relative",
              alignItems: "center",
            }}
          >
            <MdOutlineAddCircle
              style={{
                position: "absolute",
                width: 12,
                height: 12,
                left: -15,
              }}
              size={24}
              color={colors.brown}
            ></MdOutlineAddCircle>
            <span
              style={{
                textAlign: "left",
                fontFamily: "Montserrat",
                fontWeight: fontWeights.bold,
                color: colors.brown,
                fontSize: fontSizes.medium,
              }}
            >
              Nouvelle génération
            </span>
          </div>
        </div>
        <div
          className="p-3"
          style={{
            display: "flex",
            width: "80%",
            height: "100%",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              height: 26,
              overflowX: "auto",
            }}
          >
            {this.state.selectedGeneration &&
              this.state.selectedGeneration.variations
                .sort((a, b) => new Date(a.created_at) - new Date(b.created_at))
                .map((variation, index) => (
                  <div
                    className="p-1"
                    onClick={() =>
                      this.setState({ selectedVariation: variation })
                    }
                    style={{
                      display: "flex",
                      cursor: "pointer",
                      borderRadius: 10,

                      borderWidth:
                        this.state.selectedVariation?.id === variation.id
                          ? 1
                          : 0,
                      borderColor: colors.lightBlue,
                      borderStyle: "solid",
                    }}
                  >
                    <span
                      style={{
                        fontAlign: "left",
                        fontFamily: "Montserrat",
                        fontWeight: fontWeights.medium,
                        color: colors.white,
                        fontSize: fontSizes.small,
                      }}
                    >
                      Variation {variation.version}
                    </span>
                    {variation.selected && (
                      <AiFillCheckCircle
                        className="ms-1"
                        color={colors.brown}
                      ></AiFillCheckCircle>
                    )}
                    {variation.status == "in_progress" && (
                      <Spinner
                        className="ms-1"
                        size={"sm"}
                        variant="light"
                      ></Spinner>
                    )}
                  </div>
                ))}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "flex-end",

              height: "calc(100% - 30px)",
              width: "100%",
            }}
          >
            {this.renderDisplay()}
          </div>
        </div>
      </div>
    );
  }
}

class Boards extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      board: null,
      imageSelected: null,
      loading: true,
    };
  }

  createSimpleVariation = async (
    generationId,
    imageId,
    variationBaseId,
    order
  ) => {
    try {
      const data = {
        from_variation_id: variationBaseId,
        variation_type: "simple_variation",
        from_variation_order: order,
      };

      let newVariation = await createVariation(generationId, data);

      newVariation = await launchVariation(newVariation.id);

      const updatedBoard = this.state.board;

      for (let i = 0; i < updatedBoard.images.length; i++) {
        if (updatedBoard.images[i].id === imageId) {
          for (let j = 0; j < updatedBoard.images[i].generations.length; j++) {
            if (updatedBoard.images[i].generations[j].id === generationId) {
              updatedBoard.images[i].generations[j].variations.push(
                newVariation
              );
              console.log("FOOOOOOUND");
              break;
            }
          }
        }
      }
      const newImage = updatedBoard.images.find(
        (image) => image.id === imageId
      );
      console.log("newImage", newImage);
      this.setState({
        board: updatedBoard,
        imageSelected: newImage,
      });
      return newVariation;
    } catch (error) {
      console.error("Error:", error);
    }
  };

  createNewGeneration = async (imageId) => {
    try {
      const dataGeneration = {
        name: "Nouvelle génération",
        created_at: new Date().toISOString(),
      };
      console.log("dataGeneration", dataGeneration);
      const generation = await createGeneration(imageId, dataGeneration);

      const updatedBoard = this.state.board;

      for (let i = 0; i < updatedBoard.images.length; i++) {
        if (updatedBoard.images[i].id === imageId) {
          console.log("push");
          updatedBoard.images[i].generations.push(generation);
          break;
        }
      }

      this.setState({ board: updatedBoard });

      return generation;
    } catch (error) {
      console.error("Error:", error);
    }
  };

  deleteGeneration = async (generationId) => {
    try {
      await deleteGeneration(generationId);

      const updatedBoard = this.state.board;
      let selectedVariationReset = false;

      for (let i = 0; i < updatedBoard.images.length; i++) {
        for (let j = 0; j < updatedBoard.images[i].generations.length; j++) {
          if (updatedBoard.images[i].generations[j].id === generationId) {
            // Check if the selected variation is in the generation to be deleted
            if (
              updatedBoard.images[i].selected_variation &&
              updatedBoard.images[i].generations[j].variations.some(
                (variation) =>
                  variation.id === updatedBoard.images[i].selected_variation.id
              )
            ) {
              selectedVariationReset = true;
              updatedBoard.images[i].selected_variation = null;
            }
            updatedBoard.images[i].generations.splice(j, 1);
            break;
          }
        }
      }
      const newImage = updatedBoard.images.find(
        (image) => image.id === this.state.imageSelected.id
      );
      console.log("newImage", newImage);
      this.setState({
        board: updatedBoard,
        imageSelected: newImage,
        ...(selectedVariationReset && { selectedVariation: null }),
      });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  createNewComplexVariation = async (
    generationId,
    imageId,
    variationBaseId,
    order,
    type
  ) => {
    try {
      const data = {
        from_variation_id: variationBaseId,
        variation_type: "complex_variation",
        from_variation_order: order,
        variation_type: type,
      };

      let newVariation = await createVariation(generationId, data);

      const updatedBoard = this.state.board;

      for (let i = 0; i < updatedBoard.images.length; i++) {
        if (updatedBoard.images[i].id === imageId) {
          for (let j = 0; j < updatedBoard.images[i].generations.length; j++) {
            if (updatedBoard.images[i].generations[j].id === generationId) {
              updatedBoard.images[i].generations[j].variations.push(
                newVariation
              );
              console.log("FOOOOOOUND");
              break;
            }
          }
        }
      }
      const newImage = updatedBoard.images.find(
        (image) => image.id === imageId
      );
      console.log("newImage", newImage);
      this.setState({
        board: updatedBoard,
        imageSelected: newImage,
      });
      return newVariation;
    } catch (error) {
      console.error("Error:", error);
    }
  };

  async launchComplexVariation(
    generationId,
    imageId,
    variationId,
    mask = null
  ) {
    try {
      let newVariation = null;
      if (mask) newVariation = await launchVariationWithMask(variationId, mask);
      else newVariation = await launchVariation(variationId);

      const updatedBoard = this.state.board;

      for (let i = 0; i < updatedBoard.images.length; i++) {
        if (updatedBoard.images[i].id === imageId) {
          for (let j = 0; j < updatedBoard.images[i].generations.length; j++) {
            if (updatedBoard.images[i].generations[j].id === generationId) {
              for (
                let k = 0;
                k < updatedBoard.images[i].generations[j].variations.length;
                k++
              ) {
                if (
                  updatedBoard.images[i].generations[j].variations[k].id ===
                  variationId
                ) {
                  updatedBoard.images[i].generations[j].variations[k] =
                    newVariation;
                  break;
                }
              }
            }
          }
        }
      }
      const newImage = updatedBoard.images.find(
        (image) => image.id === imageId
      );
      console.log("newImage", newImage);
      this.setState({
        board: updatedBoard,
        imageSelected: newImage,
      });
      return newVariation;
    } catch (error) {
      console.error("Error:", error);
    }
  }

  deleteVariation = async (variationId) => {
    try {
      await deleteVariation(variationId);

      const updatedBoard = this.state.board;

      for (let i = 0; i < updatedBoard.images.length; i++) {
        for (let j = 0; j < updatedBoard.images[i].generations.length; j++) {
          for (
            let k = 0;
            k < updatedBoard.images[i].generations[j].variations.length;
            k++
          ) {
            if (
              updatedBoard.images[i].generations[j].variations[k].id ===
              variationId
            ) {
              updatedBoard.images[i].generations[j].variations.splice(k, 1);
              break;
            }
          }
        }
      }
      const newImage = updatedBoard.images.find(
        (image) => image.id === this.state.imageSelected.id
      );
      console.log("newImage", newImage);
      this.setState({
        board: updatedBoard,
        imageSelected: newImage,
      });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  generateAll = async () => {
    try {
      this.setState({ loading: true });
      const response = await generateAll(this.state.board.id);
      this.setState({ board: response, loading: false });
    } catch (error) {
      console.error("Error:", error);
      this.setState({ loading: false });
    }
  };
  loadBoard = async () => {
    try {
      this.setState({ loading: true });
      const board = await getBoard(this.props.boardId);
      this.setState({ board, loading: false });
    } catch (error) {
      console.error("Error:", error);
      this.setState({ loading: false });
    }
  };

  setupSocket = () => {
    const cleanBaseURL = BASE_URL.replace(/^https?:\/\//, "");
    this.socket = new WebSocket(`wss://${cleanBaseURL}ws`);

    this.socket.onopen = () => {
      console.log("WebSocket Client Connected");
    };

    this.socket.onclose = () => {
      console.log("WebSocket Client Disconnected, trying to reconnect...");
      setTimeout(() => this.setupSocket(), 5000);
    };

    this.socket.onmessage = (message) => {
      try {
        const data = JSON.parse(message.data);
        console.log("data", data.created_at);
        this.updateVariation(data);
      } catch (error) {
        console.error("Error parsing message data:", error);
      }
    };
  };

  updateVariation = (newVariation) => {
    console.log("newVariation", newVariation);
    const updatedBoard = this.state.board;
    let found = false;
    if (!updatedBoard) return;
    for (let i = 0; i < updatedBoard.images.length && !found; i++) {
      if (updatedBoard.images[i].selected_variation?.id === newVariation.id) {
        updatedBoard.images[i].selected_variation = newVariation;
      }
      for (
        let j = 0;
        j < updatedBoard.images[i].generations.length && !found;
        j++
      ) {
        for (
          let k = 0;
          k < updatedBoard.images[i].generations[j].variations.length && !found;
          k++
        ) {
          if (
            updatedBoard.images[i].generations[j].variations[k].id ===
            newVariation.id
          ) {
            updatedBoard.images[i].generations[j].variations[k] = newVariation;
            console.log();
            found = true;
            if (this.state.imageSelected)
              if (this.state.imageSelected.id === updatedBoard.images[i].id) {
                console.log("updated selected image");
                const newImageSelected = { ...updatedBoard.images[i] };
                this.setState({ imageSelected: newImageSelected });
              }
          }
        }
      }
    }
    if (found) {
      this.setState({ board: updatedBoard });
    }
  };

  componentWillUnmount() {
    this.socket.close();
  }

  componentDidMount() {
    this.loadBoard();
    this.setupSocket();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.boardId !== this.props.boardId) {
      this.loadBoard();
      this.setState({ imageSelected: null });
    }
  }

  selectImage = (image) => {
    if (this.state.imageSelected?.id === image.id)
      this.setState({ imageSelected: null });
    else this.setState({ imageSelected: image });
  };

  selectVariation = async (variation, order) => {
    try {
      const response = await selectVariation(
        this.state.imageSelected.id,
        variation.id,
        order
      );

      const updatedBoard = this.state.board;

      for (let i = 0; i < updatedBoard.images.length; i++) {
        if (updatedBoard.images[i].id === this.state.imageSelected.id) {
          updatedBoard.images[i] = response;
        }
      }

      this.setState({ imageSelected: response, board: updatedBoard });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  updateCreativity = async (weight) => {
    try {
      const creativity = { creativity: weight };
      const response = await updateBoardCreativity(
        this.state.board.id,
        creativity
      );
      const board = this.state.board;
      board.default_inspiration_image_weight = response.creativity;

      this.setState({
        board,
      });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  render() {
    return (
      <div
        style={{
          display: "flex",
          width: "100%",
          height: "100%",
          maxWidth: "100%",
          flexDirection: "column",
          alignItems: "center",
          position: "relative",
        }}
      >
        <div
          style={{
            display: "flex",
            position: "absolute",
            left: 15,
            flexDirection: "column",
            zIndex: 200,
          }}
        >
          <span
            style={{
              fontAlign: "left",
              fontFamily: "Montserrat",
              fontWeight: fontWeights.bold,
              color: colors.lightBlue,
              fontSize: fontSizes.large,
              textAlign: "left",
            }}
          >
            {this.state.board?.name}
          </span>

          <span
            style={{
              fontAlign: "left",
              fontFamily: "Montserrat",
              fontWeight: fontWeights.bold,
              color: colors.white,
              fontSize: fontSizes.small,
              textAlign: "left",
            }}
          >
            Créativité
          </span>
          <Slider
            onSliderStop={this.updateCreativity.bind(this)}
            height={14}
            min={0}
            max={1}
            width={100}
            defaultValue={this.state.board?.default_inspiration_image_weight}
            step={0.1}
          ></Slider>
        </div>

        <div style={{ position: "absolute", right: 15, zIndex: 200 }}>
          <Button
            title={"Générer"}
            onClick={this.generateAll.bind(this)}
          ></Button>
        </div>
        <Spring
          height={
            !this.state.showSettings
              ? "calc(100vh * 0.6 )"
              : "calc(100vh * 0.3 )"
          }
          width={
            !this.state.showSettings
              ? "calc(100vh * 0.6 * 1.77777777 )"
              : "calc(100vh * 0.3 * 1.77777777 )"
          }
        >
          {(props) => (
            <animated.div
              style={{
                display: "flex",
                width: props.width,
                height: props.height,
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {!this.state.loading ? (
                <MoodBoard
                  selectedImage={this.state.imageSelected}
                  selectImage={this.selectImage.bind(this)}
                  board={this.state.board}
                ></MoodBoard>
              ) : (
                <Spinner variant="light"></Spinner>
              )}
            </animated.div>
          )}
        </Spring>

        <Spring
          height={
            this.state.showSettings
              ? "calc(100vh * 0.6 )"
              : "calc(100vh * 0.3 )"
          }
          width={
            this.state.showSettings
              ? "calc(100vh * 0.6 * 1.77777777 )"
              : "calc(100vh * 0.3 * 1.77777777 )"
          }
        >
          {(props) => (
            <animated.div
              onMouseEnter={() => this.setState({ showSettings: true })}
              onMouseLeave={() => this.setState({ showSettings: false })}
              className="mt-1"
              style={{
                display: "flex",
                width: "100%",
                height: props.height,
                borderRadius: 10,
                maxHeight: props.height,

                borderWidth: 1,
                borderColor: colors.lightBlue,
                borderStyle: "solid",
                flexDirection: "column",
              }}
            >
              {!this.state.loading && (
                <Settings
                  deleteGeneration={this.deleteGeneration.bind(this)}
                  createNewGeneration={this.createNewGeneration.bind(this)}
                  launchComplexVariation={this.launchComplexVariation.bind(
                    this
                  )}
                  createNewComplexVariation={this.createNewComplexVariation.bind(
                    this
                  )}
                  launchSimpleVariation={this.createSimpleVariation.bind(this)}
                  selectVariation={this.selectVariation.bind(this)}
                  imageSelected={this.state.imageSelected}
                  board={this.state.board}
                  deleteVariation={this.deleteVariation.bind(this)}
                ></Settings>
              )}
            </animated.div>
          )}
        </Spring>
      </div>
    );
  }
}

export default Boards;
