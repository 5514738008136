import React, { Component } from "react";
import { getProject } from "../api";
import { withRouter } from "../withRouter";

import { colors } from "../colors";
import Boards from "./Boards";
import GeneralIdentity from "./GeneralIdentity";
import NavBar from "./NavBar";
import ProjectManagement from "./ProjectManagement";
import SideBar from "./SideBar";
import Viz from "./Viz";

class Project extends Component {
  constructor(props) {
    super(props);
    this.state = {
      screen: {},
      loading: true,
      screenNameSelected: "Gestion du projet",
      childIdSelected: "",
    };
  }

  renderScreen = () => {
    switch (this.state.screenNameSelected) {
      case "Gestion du projet":
        return (
          <ProjectManagement
            loadProject={this.loadProject.bind(this)}
            project={this.state.project}
          ></ProjectManagement>
        );
      case "Identité générale":
        return <GeneralIdentity project={this.state.project}></GeneralIdentity>;
      case "Planches":
        return <Boards boardId={this.state.childIdSelected}></Boards>;
      case "Visualisation":
        return <Viz project={this.state.project}></Viz>;
      default:
        return null;
    }
  };

  async loadProject() {
    try {
      const project = await getProject(this.props.params.project_id);
      this.setState({ project, loading: false });
    } catch (error) {
      console.error("Error:", error);
      this.setState({ loading: false });
    }
  }

  componentDidMount() {
    this.loadProject();
  }

  changeScreen = (screenName) => {
    this.setState({ screenNameSelected: screenName });
  };

  changeChild = (childId) => {
    this.setState({ childIdSelected: childId });
  };

  render() {
    return (
      <div
        style={{
          backgroundColor: "red",
          width: "100vw",
          height: "100vh",
          overflow: "hidden",
          maxHeight: "100vh",
          backgroundColor: colors.darkBlue,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <NavBar />
        <div
          style={{
            display: "flex",
            width: "100%",
            height: "calc(100vh - 56px)",
          }}
        >
          <SideBar
            changeScreen={this.changeScreen.bind(this)}
            changeChild={this.changeChild.bind(this)}
            project={this.state.project}
          ></SideBar>
          <div
            className="p-3"
            style={{
              display: "flex",
              width: "100%",
              height: "100%",
            }}
          >
            {this.state.project ? this.renderScreen() : null}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Project);
