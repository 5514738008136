import { Spring, animated } from "@react-spring/web";
import React from "react";
import { Spinner } from "react-bootstrap";
import { MdHideImage } from "react-icons/md";
import { downloadPpt, getBoards, getParameterGroups } from "../api";
import { colors } from "../colors";
import { BASE_URL } from "../config";
import { fontSizes } from "../fontSizes";
import { fontWeights } from "../fontWeights";
import Button from "./Button";

class Image extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  renderImage() {
    const selectedVariation = this.props.image.selected_variation;
    const image_file = selectedVariation.image_files.find(
      (image_file) => image_file.order === selectedVariation.order_selected
    );

    const imgUrl = BASE_URL + "image/" + image_file.path;

    return (
      <img
        style={{ width: "100%", height: "100%", borderRadius: 10 }}
        src={imgUrl}
      ></img>
    );
  }
  render() {
    return (
      <Spring
        from={{ opacity: 0 }}
        to={{ opacity: 1 }}
        delay={this.props.delay}
      >
        {(props) => (
          <animated.div style={{ opacity: props.opacity }}>
            <Spring
              opacity={
                this.props.selectedImage
                  ? this.props.selectedImage.id == this.props.image.id
                    ? 1
                    : 0.5
                  : 1
              }
              width={
                this.props.selectedImage?.id == this.props.image.id
                  ? `${(this.props.image.width / 763) * 120}%`
                  : `${(this.props.image.width / 763) * 100}%`
              }
              height={
                this.props.selectedImage?.id == this.props.image.id
                  ? `${(this.props.image.height / 429) * 120}%`
                  : `${(this.props.image.height / 429) * 100}%`
              }
            >
              {(props2) => (
                <animated.div
                  onClick={() => {
                    this.props.selectImage(this.props.image);
                  }}
                  style={{
                    boxShadow:
                      this.props.selectedImage?.id == this.props.image.id
                        ? "0px 0px 15px 5px rgba(0,0,0,0.1)"
                        : undefined,
                    opacity: props2.opacity,
                    transform: `scale(${props.valueRotate})`,
                    zIndex:
                      this.props.selectedImage?.id == this.props.image.id
                        ? 100
                        : 0,
                    cursor: "pointer",
                    position: "absolute",
                    left: `${(this.props.image.x / 763) * 100}%`,
                    top: `${(this.props.image.y / 429) * 100}%`,
                    width: props2.width,
                    height: props2.height,
                    borderRadius: 10,
                    backgroundColor: colors.blue,
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  {!this.props.image.selected_variation ? (
                    <MdHideImage size={35} color={colors.white} />
                  ) : this.props.image.selected_variation.status !=
                    "complete" ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        position: "relative",
                      }}
                    >
                      <Spinner variant="light"></Spinner>
                      <div style={{ position: "absolute", bottom: -20 }}>
                        <span
                          style={{
                            fontAlign: "left",
                            fontFamily: "Montserrat",
                            fontWeight: fontWeights.bold,
                            color: colors.brown,
                            fontSize: fontSizes.small,
                          }}
                        >
                          {
                            this.props.image.selected_variation.mj_infos
                              ?.progress
                          }
                          <span style={{ color: colors.white }}>%</span>
                        </span>
                      </div>
                    </div>
                  ) : (
                    this.renderImage()
                  )}
                </animated.div>
              )}
            </Spring>
          </animated.div>
        )}
      </Spring>
    );
  }
}
class MoodBoard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      heightFactor: 0.6,
      selectedImage: null,
    };
  }

  selectImage = (image) => {
    if (this.state.selectedImage?.id == image.id) {
      this.setState({ selectedImage: null });
      return;
    }
    this.setState({ selectedImage: image });
  };

  render() {
    const images = this.props.board.images;

    return (
      <div
        onMouseEnter={() => this.setState({ hover: true })}
        onMouseLeave={() => this.setState({ hover: false })}
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          position: "relative",
        }}
      >
        {images.map((image, index) => (
          <Image
            selectedImage={this.state.selectedImage}
            delay={index * 100}
            selectImage={this.selectImage.bind(this)}
            key={index}
            image={image}
          ></Image>
        ))}
      </div>
    );
  }
}

class Viz extends React.Component {
  constructor(props) {
    super(props);
    this.state = { loading: true, parameterGroups: [], boards: [] };
  }

  componentDidMount() {
    this.load();
  }
  load = async () => {
    try {
      this.setState({ loading: true });
      const parameterGroups = await getParameterGroups(this.props.project.id);
      const boards = await getBoards(this.props.project.id);
      boards.sort((a, b) => a.id - b.id);

      this.setState({ parameterGroups, boards, loading: false });
    } catch (error) {
      console.error("Error:", error);
      this.setState({ loading: false });
    }
  };

  displayColors = () => {
    const colorsParameterGroup = this.state.parameterGroups.find(
      (parameterGroup) => parameterGroup.type == "colors"
    );

    const colors = colorsParameterGroup.parameters.filter(
      (colors) => colors.activated
    );

    return (
      <div
        style={{
          display: "flex",
          width: "100%",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "flex-start",
        }}
      >
        <span
          style={{
            color: colors.lightBlue,
            fontSize: fontSizes.medium,
            fontFamily: "Montserrat",
            fontWeight: fontWeights.bold,
          }}
        >
          Couleurs
        </span>
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            alignContent: "center",
            flexWrap: "wrap",
          }}
        >
          {colors.map((color, index) => (
            <Spring
              from={{ opacity: 0, height: 60, width: 70 }}
              to={{
                opacity: 1,
                height: this.state["color" + color.id] ? 65 : 60,
                width: this.state["color" + color.id] ? 75 : 70,
              }}
            >
              {(style) => (
                <animated.div
                  onMouseEnter={() => {
                    console.log("enter");
                    this.setState({ ["color" + color.id]: true });
                  }}
                  onMouseLeave={() => {
                    this.setState({ ["color" + color.id]: false });
                  }}
                  className="me-1 mb-2"
                  style={{
                    ...style,
                    cursor: "pointer",
                    borderRadius: 10,
                    backgroundColor: color.value,
                  }}
                ></animated.div>
              )}
            </Spring>
          ))}
        </div>
      </div>
    );
  };

  displayBoards = () => {
    return this.state.boards.map((board, index) => (
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            flexDirection: "column",
          }}
        >
          <span
            style={{
              color: colors.lightBlue,
              fontSize: fontSizes.medium,
              fontFamily: "Montserrat",
              fontWeight: fontWeights.bold,
            }}
          >
            {board.name}
          </span>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "calc(100vh *0.6)",
            width: "calc(100vh *0.6* 1.777777)",
          }}
        >
          <MoodBoard board={board}></MoodBoard>;
        </div>
      </div>
    ));
  };

  download_pptx = async () => {
    try {
      const data = await downloadPpt(this.props.project.id);
    } catch (error) {
      console.error(error);
    }
  };

  render() {
    return (
      <div
        className="p-3"
        style={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          overflow: "auto",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            width: "100%",
            height: 50,
            zIndex: 100,
          }}
        >
          <Button
            title={"Exporter"}
            onClick={this.download_pptx.bind(this)}
          ></Button>
        </div>
        <div className="mb-3">
          <span
            style={{
              color: colors.brown,
              fontSize: fontSizes.large,
              fontFamily: "Montserrat",
              fontWeight: fontWeights.bold,
            }}
          >
            Visualisation
          </span>
        </div>
        <div
          style={{
            display: "flex",
            width: "100%",
            flexDirection: "column",
            justifyContent: "flex-start",
          }}
        >
          {!this.state.loading && this.displayColors()}
        </div>
        <div
          style={{
            display: "flex",
            height: "100%",
            width: "100%",
            flexDirection: "column",
            justifyContent: "flex-start",
          }}
        >
          {!this.state.loading && this.displayBoards()}
        </div>
      </div>
    );
  }
}

export default Viz;
