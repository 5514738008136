import React, { Component } from "react";
import { deleteProject, updateProject } from "../api";
import { colors } from "../colors";
import { fontSizes } from "../fontSizes";
import { fontWeights } from "../fontWeights";
import { withRouter } from "../withRouter";
import Button from "./Button";
import TextInput from "./TextInput";

class ProjectManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newProjectName: this.props.project?.name,
    };
  }

  changeProjectName = (e) => {
    this.setState({ newProjectName: e.target.value });
  };

  updateProject = async () => {
    const project = this.props.project;
    try {
      await updateProject(project.id, { name: this.state.newProjectName });
      this.props.loadProject();
    } catch (error) {
      console.error("Error:", error);
    }
  };

  componentDidUpdate(prevProps) {
    if (prevProps.project?.id !== this.props.project?.id) {
      this.setState({ newProjectName: this.props.project?.name });
    }
  }

  deleteProject = async () => {
    try {
      await deleteProject(this.props.project.id);
      this.props.navigate("/projects");
    } catch (error) {
      console.error("Error:", error);
    }
  };

  render() {
    return (
      <div
        style={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "flex-start",
        }}
      >
        <div>
          <span
            style={{
              color: colors.brown,
              fontSize: fontSizes.large,
              fontFamily: "Montserrat",
              fontWeight: fontWeights.bold,
            }}
          >
            Gestion du projet
          </span>
          <div
            className="mt-3"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <span
              style={{
                color: colors.white,
                fontSize: fontSizes.medium,
                fontFamily: "Montserrat",
                fontWeight: fontWeights.bold,
              }}
            >
              Nom du projet
            </span>
            <TextInput
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  this.updateProject();
                }
              }}
              onBlur={this.updateProject}
              placeholder="Nom"
              value={this.state.newProjectName}
              onChange={this.changeProjectName.bind(this)}
            ></TextInput>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            height: "100%",
            width: "100%",
            flexDirection: "column",
            justifyContent: "flex-end",
            alignItems: "flex-end",
          }}
        >
          <Button
            onClick={this.deleteProject.bind(this)}
            title={"Supprimer le projet"}
          ></Button>
        </div>
      </div>
    );
  }
}

export default withRouter(ProjectManagement);
