import React from 'react';
import { colors } from '../colors';

class Slider extends React.Component {
  constructor(props) {
    super(props);
    this.state = { value: this.props.defaultValue, isDragging: false };
    this.minValue = this.props.min;
    this.maxValue = this.props.max;
    this.height = this.props.height;
    this.step = this.props.step;
    this.sliderWidth = this.props.width; // Width of the slider in pixels
    this.sliderButtonWidth = 30; // Width of the slider button in pixels
    this.handleDrag = this.handleDrag.bind(this);
    this.handleMouseDown = this.handleMouseDown.bind(this);
    this.handleMouseUp = this.handleMouseUp.bind(this);
  }

  handleMouseDown(e) {
    e.preventDefault();
    this.setState({ isDragging: true });
    document.addEventListener('mousemove', this.handleDrag);
    document.addEventListener('mouseup', this.handleMouseUp);
  }

  handleMouseUp(e) {
    e.preventDefault();
    this.setState({ isDragging: false });
    document.removeEventListener('mousemove', this.handleDrag);
    document.removeEventListener('mouseup', this.handleMouseUp);
    if (this.props.onSliderStop) {
      this.props.onSliderStop(this.state.value);
    }
  }

  handleDrag(e) {
    if (!this.state.isDragging) return;
    const rect = this.sliderRef.getBoundingClientRect();
    const value = Math.max(
      this.minValue,
      Math.min(
        this.maxValue,
        ((e.clientX - rect.left) /
          (this.sliderWidth - this.sliderButtonWidth)) *
          (this.maxValue - this.minValue) +
          this.minValue
      )
    );
    this.setState({ value: Math.round(value / this.step) * this.step });
  }
  componentDidUpdate(prevProps) {
    if (prevProps.defaultValue !== this.props.defaultValue) {
      this.setState({ value: this.props.defaultValue });
    }
  }

  render() {
    const sliderStyle = {
      position: 'relative',
      width: `${this.sliderWidth}px`,
      height: this.height,
      borderRadius: 10,
      borderWidth: 1,
      borderColor: colors.brown,
      borderStyle: 'solid',
      paddingRight: 30,
      userSelect: 'none',
      cursor: 'pointer',
    };

    const sliderButtonStyle = {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      top: '50%',
      transform: 'translate(0%, -50%)',
      left: `${
        ((this.state.value - this.minValue) / (this.maxValue - this.minValue)) *
        (this.sliderWidth - this.sliderButtonWidth)
      }px`,
      width: this.sliderButtonWidth,
      height: this.height,
      backgroundColor: colors.brown,
      borderRadius: 10,
      cursor: 'pointer',
      color: colors.white,
    };

    return (
      <div
        style={sliderStyle}
        onMouseDown={this.handleMouseDown}
        ref={(ref) => {
          this.sliderRef = ref;
        }}
      >
        <div style={sliderButtonStyle}>
          <span
            style={{
              fontFamily: 'Montserrat',
              fontWeight: 'bold',
              fontSize: 10,
            }}
          >
            {Math.round(this.state.value * 100) / 100}
          </span>
        </div>
      </div>
    );
  }
}

export default Slider;
