import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Projects from './components/Projects';
import Project from './components/ProjectMainView';
import { withRouter } from './withRouter';
import { refreshToken } from './api';
import Login from './components/Login';
import Test from './components/Test';

class AuthenticatedRoutes extends Component {
  async componentDidMount() {
    try {
      const tokens = await refreshToken(localStorage.getItem('token'));
      localStorage.setItem('token', tokens.access_token);
      this.props.navigate('/projects');
    } catch (error) {
      console.error('Error:', error);
      this.props.navigate('/login');
    }
  }

  render() {
    return (
      <div className='App'>
        <Routes>
          <Route path='/projects' element={<Projects />} />
          <Route path='/projects/:project_id' element={<Project />} />-
        </Routes>
      </div>
    );
  }
}

const AuthenticatedRoutesWithRouter = withRouter(AuthenticatedRoutes);

class App extends Component {
  render() {
    return (
      <Router>
        <Routes>
          <Route path='/login' element={<Login />} />
          <Route path='*' element={<AuthenticatedRoutesWithRouter />} />
        </Routes>
      </Router>
    );
  }
}

export default App;
