import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { colors } from '../colors';
import { fontWeights } from '../fontWeights';
import { fontSizes } from '../fontSizes';
import { withRouter } from '../withRouter';

class NavBar extends Component {
  render() {
    return (
      <div
        style={{
          height: 64,
          width: '100%',
          borderBottomWidth: 1,
          borderBottomColor: colors.white,
          borderBottomStyle: 'solid',
          display: 'flex',
        }}
      >
        <Container fluid className='px-4'>
          <Row
            style={{
              width: '100%',
              height: '100%',
            }}
          >
            <Col
              style={{
                height: '100%',
              }}
              md={8}
            >
              <Row
                style={{
                  width: '100%',
                  height: '100%',
                }}
              >
                <Col
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    height: '100%',
                  }}
                  md={3}
                >
                  <img
                    style={{ height: '60%' }}
                    src={require('../assets/logo.png')}
                  ></img>
                </Col>
                <Col
                  style={{
                    height: '100%',
                  }}
                  md={9}
                >
                  <div
                    style={{
                      width: '100%',
                      height: '100%',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        cursor: 'pointer',
                      }}
                    >
                      <div
                        className='me-2'
                        style={{
                          width: 6,
                          height: 6,
                          borderRadius: 3,
                          border: 'white 1px solid',
                        }}
                      ></div>
                      <span
                        onClick={() => this.props.navigate('/projects')}
                        className='me-2'
                        style={{
                          fontFamily: 'Montserrat',

                          color: colors.white,
                          fontWeight: fontWeights.medium,
                        }}
                      >
                        Projets
                      </span>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>

            <Col md={4} className='m-0 p-0'>
              <div
                style={{
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                }}
              >
                <span
                  style={{
                    fontFamily: 'Montserrat',
                    fontWeight: fontWeights.black,
                    color: colors.white,
                    fontSize: fontSizes.large,
                  }}
                >
                  Mood<span style={{ color: colors.lightBlue }}>IA</span>{' '}
                  <span style={{ fontWeight: fontWeights.bold }}>Platform</span>
                </span>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default withRouter(NavBar);
