import axios from "axios";
import { BASE_URL } from "./config";

const api = axios.create({
  baseURL: BASE_URL,
});
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);
export const createProject = async (project) => {
  try {
    const response = await api.post("/projects/", project);
    return response.data;
  } catch (error) {
    console.error("Error creating project:", error);
    throw error;
  }
};

export const getProjects = async (skip = 0, limit = 100) => {
  try {
    const response = await api.get("/projects/", { params: { skip, limit } });
    return response.data;
  } catch (error) {
    console.error("Error getting projects:", error);
    throw error;
  }
};

export const getProject = async (projectId) => {
  try {
    const response = await api.get(`/projects/${projectId}`);
    return response.data;
  } catch (error) {
    console.error(`Error getting project with ID ${projectId}:`, error);
    throw error;
  }
};

export const updateProject = async (projectId, project) => {
  try {
    const response = await api.put(`/projects/${projectId}`, project);
    return response.data;
  } catch (error) {
    console.error(`Error updating project with ID ${projectId}:`, error);
    throw error;
  }
};

export const getProjectKeywords = async (projectId) => {
  try {
    const response = await api.get(`/projects/${projectId}/keywords/`);
    return response.data;
  } catch (error) {
    console.error(
      `Error getting keywords for project with ID ${projectId}:`,
      error
    );
    throw error;
  }
};

export const getKeyword = async (keywordId) => {
  try {
    const response = await api.get(`/keywords/${keywordId}`);
    return response.data;
  } catch (error) {
    console.error(`Error getting keyword with ID ${keywordId}:`, error);
    throw error;
  }
};

export const createProjectKeyword = async (projectId, keyword) => {
  try {
    const response = await api.post(
      `/projects/${projectId}/keywords/`,
      keyword
    );
    return response.data;
  } catch (error) {
    console.error(
      `Error creating keyword for project with ID ${projectId}:`,
      error
    );
    throw error;
  }
};

export const updateKeyword = async (keywordId, keyword) => {
  try {
    const response = await api.put(`/keywords/${keywordId}`, keyword);
    return response.data;
  } catch (error) {
    console.error(`Error updating keyword with ID ${keywordId}:`, error);
    throw error;
  }
};

export const deleteKeyword = async (keywordId) => {
  try {
    const response = await api.delete(`/keywords/${keywordId}`);
    return response.data;
  } catch (error) {
    console.error(`Error deleting keyword with ID ${keywordId}:`, error);
    throw error;
  }
};

export const getParameterGroups = async (projectId) => {
  try {
    const response = await api.get(`/projects/${projectId}/parameter_groups/`);
    return response.data;
  } catch (error) {
    console.error(
      `Error getting parameter groups for project with ID ${projectId}:`,
      error
    );
    throw error;
  }
};

export const getParameterGroup = async (parameterGroupId) => {
  try {
    const response = await api.get(`/parameter_groups/${parameterGroupId}`);
    return response.data;
  } catch (error) {
    console.error(
      `Error getting parameter group with ID ${parameterGroupId}:`,
      error
    );
    throw error;
  }
};

export const createParameterGroup = async (parameterGroup) => {
  try {
    const response = await api.post(`/parameter_groups/`, parameterGroup);
    return response.data;
  } catch (error) {
    console.error(`Error creating parameter group:`, error);
    throw error;
  }
};

export const updateParameterGroup = async (
  parameterGroupId,
  parameterGroup
) => {
  try {
    const response = await api.put(
      `/parameter_groups/${parameterGroupId}`,
      parameterGroup
    );
    return response.data;
  } catch (error) {
    console.error(
      `Error updating parameter group with ID ${parameterGroupId}:`,
      error
    );
    throw error;
  }
};

export const deleteParameterGroup = async (parameterGroupId) => {
  try {
    const response = await api.delete(`/parameter_groups/${parameterGroupId}`);
    return response.data;
  } catch (error) {
    console.error(
      `Error deleting parameter group with ID ${parameterGroupId}:`,
      error
    );
    throw error;
  }
};

export const extractColorsFromImage = async (parameterGroupId, file) => {
  try {
    const response = await api.post(
      `/extract_colors_from_image/${parameterGroupId}`,
      file
    );
    return response.data;
  } catch (error) {
    console.error(
      `Error extracting colors from image for parameter group with ID ${parameterGroupId}:`,
      error
    );
    throw error;
  }
};

export const generateColors = async (parameterGroupId) => {
  try {
    const response = await api.post(`/generate/${parameterGroupId}`);
    return response.data;
  } catch (error) {
    console.error(
      `Error generating colors for parameter group with ID ${parameterGroupId}:`,
      error
    );
    throw error;
  }
};

export const getParameters = async (skip = 0, limit = 100) => {
  try {
    const response = await api.get(`/parameters?skip=${skip}&limit=${limit}`);
    return response.data;
  } catch (error) {
    console.error(`Error getting parameters:`, error);
    throw error;
  }
};

export const getParameter = async (parameterId) => {
  try {
    const response = await api.get(`/parameters/${parameterId}`);
    return response.data;
  } catch (error) {
    console.error(`Error getting parameter with ID ${parameterId}:`, error);
    throw error;
  }
};

export const createParameter = async (parameterGroupId, parameter) => {
  try {
    console.log(parameter);
    const response = await api.post(
      `/parameter_groups/${parameterGroupId}/parameters/`,
      parameter
    );
    return response.data;
  } catch (error) {
    console.error(
      `Error creating parameter for parameter group with ID ${parameterGroupId}:`,
      error
    );
    throw error;
  }
};

export const deleteParameter = async (parameterId) => {
  try {
    const response = await api.delete(`/parameters/${parameterId}`);
    return response.data;
  } catch (error) {
    console.error(`Error deleting parameter with ID ${parameterId}:`, error);
    throw error;
  }
};

export const updateParameter = async (parameterId, parameter) => {
  try {
    const response = await api.put(`/parameters/${parameterId}`, parameter);
    return response.data;
  } catch (error) {
    console.error(`Error updating parameter with ID ${parameterId}:`, error);
    throw error;
  }
};
export const getBoards = async (projectId) => {
  try {
    const response = await api.get(`/projects/${projectId}/boards/`);
    return response.data;
  } catch (error) {
    console.error(
      `Error getting boards for project with ID ${projectId}:`,
      error
    );
    throw error;
  }
};

export const getBoardsSideBar = async (projectId) => {
  try {
    const response = await api.get(`/projects/${projectId}/boards_side_bar/`);
    return response.data;
  } catch (error) {
    console.error(
      `Error getting boards for project with ID ${projectId}:`,
      error
    );
    throw error;
  }
};

export const getBoard = async (boardId) => {
  try {
    const response = await api.get(`/boards/${boardId}`);
    return response.data;
  } catch (error) {
    console.error(`Error getting board with ID ${boardId}:`, error);
    throw error;
  }
};

export const updateBoard = async (boardId, board) => {
  try {
    const response = await api.put(`/boards/${boardId}`, board);
    return response.data;
  } catch (error) {
    console.error(`Error updating board with ID ${boardId}:`, error);
    throw error;
  }
};

export const updateBoardCreativity = async (boardId, creativity) => {
  try {
    const response = await api.put(`/boards/${boardId}/creativity`, creativity);
    return response.data;
  } catch (error) {
    console.error(`Error updating board with ID ${boardId}:`, error);
    throw error;
  }
};

export const deleteBoard = async (boardId) => {
  try {
    const response = await api.delete(`/boards/${boardId}`);
    return response.data;
  } catch (error) {
    console.error(`Error deleting board with ID ${boardId}:`, error);
    throw error;
  }
};

export const generateAll = async (boardId) => {
  try {
    const response = await api.post(`/boards/${boardId}/generate_all`);
    return response.data;
  } catch (error) {
    console.error(`Error generating all for board with ID ${boardId}:`, error);
    throw error;
  }
};

export const selectVariation = async (imageId, variationId, order) => {
  try {
    const response = await api.post(
      `/images/${imageId}/select_variation/${variationId}/${order}`
    );
    return response.data;
  } catch (error) {
    console.error(
      `Error selecting variation with ID ${variationId} for image with ID ${imageId}:`,
      error
    );
    throw error;
  }
};

export const launchVariation = async (variationId) => {
  try {
    const response = await api.post(`/variations/${variationId}/launch`, {
      base64: null,
    });
    return response.data;
  } catch (error) {
    console.error(`Error launching variation with ID ${variationId}:`, error);
    throw error;
  }
};

export const readVariation = async (variationId) => {
  try {
    const response = await api.get(`/variations/${variationId}`);
    return response.data;
  } catch (error) {
    console.error(`Error reading variation with ID ${variationId}:`, error);
    throw error;
  }
};

export const createVariation = async (generationId, variation) => {
  try {
    const response = await api.post(
      `/generations/${generationId}/variations/`,
      variation
    );
    return response.data;
  } catch (error) {
    console.error(
      `Error creating variation for generation with ID ${generationId}:`,
      error
    );
    throw error;
  }
};

export const updateVariation = async (variationId, variation) => {
  try {
    const response = await api.put(`/variations/${variationId}`, variation);
    return response.data;
  } catch (error) {
    console.error(`Error updating variation with ID ${variationId}:`, error);
    throw error;
  }
};

export const deleteVariation = async (variationId) => {
  try {
    const response = await api.delete(`/variations/${variationId}`);
    return response.data;
  } catch (error) {
    console.error(`Error deleting variation with ID ${variationId}:`, error);
    throw error;
  }
};

export const readVariationParameterGroups = async (variationId) => {
  try {
    const response = await api.get(
      `/variations/${variationId}/parameter_groups`
    );
    return response.data;
  } catch (error) {
    console.error(`Error reading variation with ID ${variationId}:`, error);
    throw error;
  }
};

export const readGenerations = async (imageId) => {
  try {
    const response = await api.get(`/images/${imageId}/generations/`);
    return response.data;
  } catch (error) {
    console.error(
      `Error reading generations for image with ID ${imageId}:`,
      error
    );
    throw error;
  }
};

export const readGeneration = async (generationId) => {
  try {
    const response = await api.get(`/generations/${generationId}`);
    return response.data;
  } catch (error) {
    console.error(`Error reading generation with ID ${generationId}:`, error);
    throw error;
  }
};

export const createGeneration = async (imageId, generation) => {
  try {
    const response = await api.post(
      `/images/${imageId}/generations/`,
      generation
    );
    return response.data;
  } catch (error) {
    console.error(
      `Error creating generation for image with ID ${imageId}:`,
      error
    );
    throw error;
  }
};

export const updateGeneration = async (generationId, generation) => {
  try {
    const response = await api.put(`/generations/${generationId}`, generation);
    return response.data;
  } catch (error) {
    console.error(`Error updating generation with ID ${generationId}:`, error);
    throw error;
  }
};

export const deleteGeneration = async (generationId) => {
  try {
    const response = await api.delete(`/generations/${generationId}`);
    return response.data;
  } catch (error) {
    console.error(`Error deleting generation with ID ${generationId}:`, error);
    throw error;
  }
};

export const downloadPpt = async (projectId) => {
  try {
    const response = await api.get(`/projects/${projectId}/download_ppt`, {
      responseType: "blob",
    });
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "file.pptx"); // or any other filename you want
    document.body.appendChild(link);
    link.click();
  } catch (error) {
    console.error(
      `Error downloading ppt for project with ID ${projectId}:`,
      error
    );
    throw error;
  }
};

export const deleteProject = async (projectId) => {
  try {
    const response = await api.delete(`/projects/${projectId}`);
    return response.data;
  } catch (error) {
    console.error(`Error deleting project with ID ${projectId}:`, error);
    throw error;
  }
};

export const login = async (username, password) => {
  try {
    const formData = new FormData();
    formData.append("username", username);
    formData.append("password", password);
    const response = await api.post("/token", formData);
    if (!response.data.access_token) {
      throw new Error("Invalid credentials");
    }
    return response.data;
  } catch (error) {
    console.error("Error logging in:", error);
    throw error;
  }
};

export const refreshToken = async (token) => {
  try {
    const response = await api.post("/token/refresh", { token });
    if (!response.data.access_token) {
      throw new Error("Invalid token");
    }
    return response.data;
  } catch (error) {
    console.error("Error refreshing token:", error);
    throw error;
  }
};

export const getImageEmbeddings = async (imageFileId) => {
  try {
    const response = await api.get(
      `/image_files/get_embeddings/${imageFileId}`
    );
    if (!response.data.embeddings) {
      throw new Error("Embeddings not found");
    }
    return response.data;
  } catch (error) {
    console.error(
      `Error getting embeddings for image with ID ${imageFileId}:`,
      error
    );
    throw error;
  }
};

export const launchVariationWithMask = async (
  variationId,
  base64Image = null
) => {
  try {
    const data = {
      base64: base64Image,
    };
    const response = await api.post(`/variations/${variationId}/launch`, data);

    if (!response.data) {
      throw new Error("Variation not found");
    }
    return response.data;
  } catch (error) {
    console.error(`Error launching variation with ID ${variationId}:`, error);
    throw error;
  }
};
