import React, { Component } from "react";
import { Row, Col, Spinner } from "react-bootstrap";
import { colors } from "../colors";
import { fontWeights } from "../fontWeights";
import { fontSizes } from "../fontSizes";
import { IoIosArrowDropleftCircle } from "react-icons/io";
import { getBoards, getBoardsSideBar } from "../api";
import { Spring, animated } from "@react-spring/web";

class SideBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sideBarVisible: true,
      nameSelected: "Gestion du projet",
      childSelected: null,
      list: [
        {
          name: "Gestion du projet",
          childs: [],
        },
        {
          name: "Identité générale",
          childs: [],
        },
        {
          name: "Planches",
          childs: [],
        },
        {
          name: "Visualisation",
          childs: [],
        },
      ],
    };
  }

  componentDidMount() {
    if (this.props.project) this.getBoards();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.project !== this.props.project) this.getBoards();
  }

  getBoards = async () => {
    try {
      this.setState({ loading: true });
      const boards = await getBoardsSideBar(this.props.project.id);

      boards.sort((a, b) => a.id - b.id);
      const updatedList = this.state.list.map((item) => {
        if (item.name === "Planches") {
          return { ...item, childs: boards };
        }
        return item;
      });
      this.setState({ list: updatedList, loading: false });
    } catch (error) {
      console.error(`Error getting boards:`, error);
    }
  };

  render() {
    return (
      <Spring
        width={this.state.sideBarVisible ? "20%" : "5%"}
        opacity={this.state.sideBarVisible ? 1 : 0}
      >
        {(props) => (
          <animated.div
            style={{
              ...props,
              opacity: 1,
              height: "100%",
              backgroundColor: colors.blue,
              position: "relative",
            }}
          >
            <div
              onClick={() =>
                this.setState({ sideBarVisible: !this.state.sideBarVisible })
              }
              style={{
                transform: !this.state.sideBarVisible
                  ? "rotate(180deg)"
                  : "rotate(0deg)",
                position: "absolute",
                top: 10,
                zIndex: 100,
                right: 10,
                cursor: "pointer",
              }}
            >
              <IoIosArrowDropleftCircle
                color={colors.brown}
                size={26}
              ></IoIosArrowDropleftCircle>
            </div>
            {this.state.sideBarVisible && (
              <animated.div
                style={{
                  ...props,
                  display: "flex",
                  width: "100%",
                  flexDirection: "column",
                }}
              >
                <Row className="pt-3 ps-5 pe-2">
                  <Col md={12}>
                    <div
                      style={{
                        position: "relative",
                        display: "flex",
                        height: 55,
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          width: 55,
                          height: 55,
                          borderRadius: 55 / 2,
                          backgroundColor: colors.brown,
                          position: "absolute",
                          zIndex: 0,
                          top: 0,
                          left: -55 / 3,
                        }}
                      ></div>
                      <span
                        style={{
                          zIndex: 1,
                          color: colors.white,
                          fontWeight: fontWeights.black,
                          fontSize: fontSizes.large,
                          textAlign: "left",
                          fontFamily: "Montserrat",
                        }}
                      >
                        {this.props.project?.name}
                      </span>
                    </div>
                  </Col>
                </Row>
                {this.state.list.map((item, index) => (
                  <Row className="ps-5 pt-3" key={index}>
                    <Col
                      md={12}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        position: "relative",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          position: "relative",
                          alignItems: "center",
                          flexDirection: "row",
                        }}
                      >
                        <span
                          onClick={() =>
                            item.name != "Planches"
                              ? (this.setState({
                                  nameSelected: item.name,
                                  childSelected: null,
                                }),
                                this.props.changeScreen(item.name))
                              : null
                          }
                          style={{
                            cursor:
                              item.name != "Planches" ? "pointer" : undefined,
                            color: colors.white,
                            fontWeight:
                              this.state.nameSelected == item.name
                                ? fontWeights.black
                                : fontWeights.bold,
                            fontSize: fontSizes.medium,
                            textAlign: "left",
                            fontFamily: "Montserrat",
                          }}
                        >
                          {item.name}
                        </span>
                        {item.name === this.state.nameSelected && (
                          <div
                            style={{
                              position: "absolute",
                              left: -15,
                              width: 10,
                              height: 10,

                              borderRadius: 20 / 2,
                              backgroundColor: colors.brown,
                            }}
                          ></div>
                        )}
                      </div>
                      {item.name === "Planches" && item.childs.length > 0 && (
                        <div
                          className="ps-2 mt-2"
                          style={{
                            borderLeftWidth: 0.1,
                            borderLeftStyle: "solid",
                            borderLeftColor: colors.white,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                          }}
                        >
                          {this.state.loading ? (
                            <Spinner size="sm" variant="light"></Spinner>
                          ) : (
                            item.childs.map((child, childIndex) => (
                              <span
                                onClick={() => (
                                  this.setState({
                                    nameSelected: "Planches",
                                    childSelected: child.id,
                                  }),
                                  this.props.changeScreen("Planches"),
                                  this.props.changeChild(child.id)
                                )}
                                className="mb-2"
                                key={childIndex}
                                style={{
                                  cursor: "pointer",
                                  color: colors.white,
                                  fontWeight:
                                    this.state.childSelected == child.id
                                      ? fontWeights.bold
                                      : fontWeights.medium,
                                  fontSize: fontSizes.medium,
                                  fontFamily: "Montserrat",
                                  textAlign: "left",
                                }}
                              >
                                {child.name}
                              </span>
                            ))
                          )}
                        </div>
                      )}
                    </Col>
                  </Row>
                ))}
              </animated.div>
            )}
          </animated.div>
        )}
      </Spring>
    );
  }
}

export default SideBar;
