import React, { Component } from "react";
import { login } from "../api";
import { colors } from "../colors";
import { withRouter } from "../withRouter";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
    };
  }

  handleInputChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await login(this.state.username, this.state.password);
      localStorage.setItem("token", response.access_token);
      this.props.navigate("/projects");
    } catch (error) {
      console.error("Login failed:", error);
    }
  };

  render() {
    return (
      <div
        style={{
          backgroundColor: "red",
          display: "flex",
          width: "100vw",
          height: "100vh",
          backgroundColor: "#f7f5f5",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div style={{ width: 350, display: "flex", flexDirection: "column" }}>
          <img className="mb-2" src={require("../assets/logo_black.png")} />
          <input
            type="text"
            name="username"
            placeholder="Identifiant"
            value={this.state.username}
            onChange={this.handleInputChange}
            style={{
              margin: "10px 0",
              padding: "4px 11px",
              borderColor: "rgba(0,0,0,0.2)",
              backgroundColor: "transparent",
              borderWidth: 1,
              borderStyle: "solid",
              borderRadius: 2,
              paddingVertical: 5,
            }}
          />
          <input
            type="password"
            name="password"
            placeholder="Mot de passe"
            value={this.state.password}
            onChange={this.handleInputChange}
            style={{
              margin: "10px 0",
              padding: "4px 11px",
              borderColor: "rgba(0,0,0,0.2)",

              backgroundColor: "transparent",
              borderWidth: 1,
              borderStyle: "solid",
              borderRadius: 2,
              paddingVertical: 5,
            }}
          />
          <button
            onClick={this.handleSubmit}
            style={{
              margin: "10px 0",
              backgroundColor: "#a28a66",
              borderWidth: 0,
              padding: "5px 0",
              color: colors.white,
            }}
          >
            Login
          </button>
        </div>
      </div>
    );
  }
}

export default withRouter(Login);
