import { Spring, animated } from "@react-spring/web";
import React, { Component } from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import { ChromePicker } from "react-color";
import { FaPlus } from "react-icons/fa";
import { MdDelete, MdFileUpload } from "react-icons/md";
import {
  createParameter,
  createProjectKeyword,
  deleteKeyword,
  deleteParameter,
  extractColorsFromImage,
  generateColors,
  getParameterGroups,
  getProjectKeywords,
  updateParameter,
} from "../api";
import { colors } from "../colors";
import { fontSizes } from "../fontSizes";
import { fontWeights } from "../fontWeights";
import Button from "./Button";
import Slider from "./Slider";
import TextInput from "./TextInput";

class Keyword extends Component {
  constructor(props) {
    super(props);
    this.state = { hover: false, addColor: true, circleSize: 22 };
  }

  render() {
    return (
      <Spring
        from={{ globalOpcaity: 0 }}
        to={{
          globalOpcaity: 1,
          hoverOpacity: this.state.hover ? 1 : 0,
          circleSize: this.state.hover ? 26 : 22,
          backgroundColor: this.state.hover ? colors.brown : colors.lightBlue,
        }}
      >
        {(props) => (
          <animated.div
            className="ms-4 mb-2"
            onMouseEnter={() => this.setState({ hover: true })}
            onMouseLeave={() => this.setState({ hover: false })}
            onClick={() => this.props.deleteKeyword(this.props.keyword.id)}
            style={{
              opacity: props.globalOpcaity,
              cursor: "pointer",
              position: "relative",
              display: "flex",
              height: props.circleSize,
              alignItems: "center",
            }}
          >
            <animated.div
              style={{
                width: props.circleSize,
                height: props.circleSize,
                borderRadius: "50%",
                backgroundColor: props.backgroundColor,
                position: "absolute",
                zIndex: 0,
                left: -22 / 3,
              }}
            ></animated.div>
            <span
              style={{
                zIndex: 1,
                color: colors.white,
                fontWeight: fontWeights.black,
                fontSize: fontSizes.large,
                textAlign: "left",
                fontFamily: "Montserrat",
              }}
            >
              {this.props.keyword.name}
            </span>

            <animated.div
              style={{
                opacity: props.hoverOpacity,
                position: "absolute",
                zIndex: 10,
                right: -15,
                top: -10,
              }}
            >
              <MdDelete color={colors.brown} />
            </animated.div>
          </animated.div>
        )}
      </Spring>
    );
  }
}

class Keywords extends Component {
  constructor(props) {
    super(props);
    this.state = {
      keywords: [],
      loading: false,
      addValue: "",
    };
  }

  componentDidMount() {
    this.getKeywords();
  }

  getKeywords = async () => {
    try {
      const keywords = await getProjectKeywords(this.props.project.id);
      this.setState({ keywords });
    } catch (error) {
      console.error("Error getting keywords:", error);
    }
  };

  addKeyword = async (keywordName) => {
    try {
      const keyword = { name: keywordName };
      const newKeyword = await createProjectKeyword(
        this.props.project.id,
        keyword
      );
      this.setState({ keywords: [...this.state.keywords, newKeyword] });
    } catch (error) {
      console.error("Error adding keyword:", error);
    }
  };

  deleteKeyword = async (keywordId) => {
    try {
      await deleteKeyword(keywordId);
      this.setState({
        keywords: this.state.keywords.filter(
          (keyword) => keyword.id !== keywordId
        ),
      });
    } catch (error) {
      console.error("Error deleting keyword:", error);
    }
  };

  render() {
    return (
      <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {this.state.keywords.map((keyword) => (
            <Keyword
              deleteKeyword={this.deleteKeyword.bind(this)}
              keyword={keyword}
            ></Keyword>
          ))}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "40%",
            alignItems: "center",
          }}
        >
          <FaPlus
            className="me-2"
            size={fontSizes.small}
            color={colors.brown}
          ></FaPlus>
          <TextInput
            onKeyPress={async (e) => {
              if (e.key === "Enter") {
                await this.addKeyword(this.state.addValue);
                this.setState({ addValue: "" });
              }
            }}
            onChange={(e) => this.setState({ addValue: e.target.value })}
            value={this.state.addValue}
            placeholder={"Keyword"}
          ></TextInput>
        </div>
      </div>
    );
  }
}

class Colors extends Component {
  constructor(props) {
    super(props);
    this.state = {
      parameterGroup: this.props.parameterGroup,
      colorSelectedId: null,
      colorPickerSelected: "#fff",
      addColor: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.parameterGroup !== this.props.parameterGroup) {
      this.setState({ parameterGroup: this.props.parameterGroup });
    }
  }

  handleImageUpload = async (event) => {
    this.setState({ loadingLogoGeneration: true });
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      this.setState({ uploadedImage: reader.result });
    };
    reader.readAsDataURL(file);
    try {
      const formData = new FormData();
      formData.append("file", file);
      const response = await extractColorsFromImage(
        this.state.parameterGroup.id,
        formData
      );
      this.setState({
        parameterGroup: response,
        loadingLogoGeneration: false,
      });
    } catch (error) {
      console.error(
        `Error extracting colors from image for parameter group with ID ${this.state.parameterGroup.id}:`,
        error
      );
    }
  };

  selectColor = async (selectedColor) => {
    try {
      const data = { activated: !selectedColor.activated };

      const updatedParameter = await updateParameter(selectedColor.id, data);
      this.setState((prevState) => ({
        parameterGroup: {
          ...prevState.parameterGroup,
          parameters: prevState.parameterGroup.parameters.map((parameter) =>
            parameter.id === selectedColor.id ? updatedParameter : parameter
          ),
        },
      }));
    } catch (error) {
      console.error(`Error updating color with ID ${selectedColor.id}:`, error);
    }
  };

  updateColorWeight = async (colorId, weight) => {
    const color = this.state.parameterGroup.parameters.find(
      (color) => color.id === colorId
    );

    try {
      const data = { weight: weight };
      const updatedParameter = await updateParameter(color.id, data);
      this.setState((prevState) => ({
        parameterGroup: {
          ...prevState.parameterGroup,
          parameters: prevState.parameterGroup.parameters.map((parameter) =>
            parameter.id === color.id ? updatedParameter : parameter
          ),
        },
      }));
    } catch (error) {
      console.error(`Error updating color with ID ${color.id}:`, error);
    }
  };

  addColor = async () => {
    try {
      const newColor = {
        name: "",
        description: "",
        value: this.state.colorPickerSelected,
        activated: false,
        weight: 1.0,
      };
      const response = await createParameter(
        this.state.parameterGroup.id,
        newColor
      );
      this.setState((prevState) => ({
        parameterGroup: {
          ...prevState.parameterGroup,
          parameters: [...prevState.parameterGroup.parameters, response],
        },
      }));
    } catch (error) {
      console.error(
        `Error creating color for parameter group with ID ${this.state.parameterGroup.id}:`,
        error
      );
    }
  };

  deleteColor = async () => {
    try {
      const response = await deleteParameter(this.state.colorSelectedId);
      this.setState((prevState) => ({
        parameterGroup: {
          ...prevState.parameterGroup,
          parameters: prevState.parameterGroup.parameters.filter(
            (parameter) => parameter.id !== this.state.colorSelectedId
          ),
        },
        colorSelectedId: null,
      }));
    } catch (error) {
      console.error(
        `Error deleting color with ID ${this.state.colorSelectedId}:`,
        error
      );
    }
  };

  generateColors = async () => {
    try {
      this.setState({ loadingGeneration: true });
      const response = await generateColors(this.state.parameterGroup.id);

      this.setState({
        parameterGroup: response,
        loadingGeneration: false,
      });
    } catch (error) {
      console.error(
        `Error generating colors for parameter group with ID ${this.state.parameterGroup.id}:`,
        error
      );
    }
  };

  render() {
    let colorSelected = null;
    if (this.state.parameterGroup?.parameters?.length > 0)
      colorSelected = this.state.parameterGroup.parameters.find(
        (color) => color.id === this.state.colorSelectedId
      );
    return (
      <div
        className="mt-4"
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          alignItems: "flex-start",
        }}
      >
        <span
          style={{
            fontFamily: "Montserrat",
            color: colors.white,
            fontWeight: fontWeights.bold,
            fontSize: fontSizes.large,
          }}
        >
          Couleurs
        </span>
        <div
          className="mt-2"
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            alignItems: "flex-start",
          }}
        >
          <div
            className="mt-2"
            style={{
              display: "flex",
              flexDirection: "column",
              width: "50%",
              alignItems: "flex-start",
            }}
          >
            <span
              style={{
                fontFamily: "Montserrat",
                color: colors.lightBlue,
                fontWeight: fontWeights.bold,
                fontSize: fontSizes.medium,
              }}
            >
              Proposées
            </span>
            <div
              className="mt-1"
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  alignContent: "flex-start",
                  flexWrap: "wrap",
                }}
              >
                {this.state.parameterGroup?.parameters?.filter(
                  (color) => !color.activated
                ).length > 0 &&
                  this.state.parameterGroup.parameters
                    .filter((color) => !color.activated)
                    .map((color, index) => (
                      <Spring
                        from={{ opacity: 0, height: 60, width: 70 }}
                        to={{
                          opacity: 1,
                          height:
                            this.state["color" + color.id] ||
                            this.state.colorSelectedId == color.id
                              ? 65
                              : 60,
                          width:
                            this.state["color" + color.id] ||
                            this.state.colorSelectedId == color.id
                              ? 75
                              : 70,
                        }}
                      >
                        {(style) => (
                          <animated.div
                            onMouseEnter={() => {
                              this.setState({ ["color" + color.id]: true });
                            }}
                            onMouseLeave={() => {
                              this.setState({ ["color" + color.id]: false });
                            }}
                            onClick={() =>
                              this.setState({
                                colorSelectedId: color.id,
                                addColor: false,
                              })
                            }
                            className="me-1 mb-2"
                            style={{
                              ...style,
                              cursor: "pointer",
                              borderRadius: 10,
                              backgroundColor: color.value,
                            }}
                          ></animated.div>
                        )}
                      </Spring>
                    ))}

                <Spring
                  from={{
                    backgroundColor: "transparent",
                    color: colors.lightBlue,
                  }}
                  to={{
                    backgroundColor: this.state["generationColor"]
                      ? colors.lightBlue
                      : "transparent",
                    color: this.state["generationColor"]
                      ? colors.white
                      : colors.lightBlue,
                  }}
                >
                  {(style) => (
                    <animated.div
                      className="me-1 mb-2"
                      onClick={() =>
                        !this.state.loadingGeneration && this.generateColors()
                      }
                      onMouseEnter={() => {
                        this.setState({ ["generationColor"]: true });
                      }}
                      onMouseLeave={() => {
                        this.setState({
                          ["generationColor"]: false,
                        });
                      }}
                      style={{
                        ...style,
                        display: "flex",
                        flexDirection: "column",
                        height: 60,
                        width: 100,
                        borderRadius: 10,
                        borderStyle: "solid",
                        borderWidth: 1,
                        borderColor: colors.lightBlue,
                        cursor: "pointer",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {this.state.loadingGeneration ? (
                        <Spinner variant="light"></Spinner>
                      ) : (
                        <>
                          <animated.span
                            style={{
                              fontFamily: "Montserrat",
                              color: style.color,
                              fontWeight: fontWeights.black,
                              fontSize: fontSizes.large,
                            }}
                          >
                            IA
                          </animated.span>
                          <animated.span
                            style={{
                              fontFamily: "Montserrat",
                              color: style.color,
                              fontWeight: fontWeights.medium,
                              fontSize: fontSizes.small,
                            }}
                          >
                            génération
                          </animated.span>
                        </>
                      )}
                    </animated.div>
                  )}
                </Spring>
                <Spring
                  from={{
                    backgroundColor: "transparent",
                    color: colors.lightBlue,
                  }}
                  to={{
                    backgroundColor: this.state["logoColor"]
                      ? colors.lightBlue
                      : "transparent",
                    color: this.state["logoColor"]
                      ? colors.white
                      : colors.lightBlue,
                  }}
                >
                  {(style) => (
                    <animated.div
                      onMouseEnter={() => {
                        this.setState({ ["logoColor"]: true });
                      }}
                      onMouseLeave={() => {
                        this.setState({
                          ["logoColor"]: false,
                        });
                      }}
                      className="me-1 mb-2"
                      style={{
                        ...style,
                        display: "flex",
                        flexDirection: "column",
                        height: 60,
                        width: 100,
                        borderRadius: 10,
                        borderStyle: "solid",
                        borderWidth: 1,
                        borderColor: colors.lightBlue,
                        cursor: "pointer",
                        justifyContent: "center",
                        alignItems: "center",
                        position: "relative",
                      }}
                    >
                      {!this.state.loadingLogoGeneration && (
                        <input
                          type="file"
                          onChange={this.handleImageUpload}
                          style={{
                            opacity: 0,
                            position: "absolute",
                            left: 0,
                            top: 0,
                            width: "100%",
                            height: "100%",
                            cursor: "pointer",
                          }}
                        ></input>
                      )}

                      {this.state.loadingLogoGeneration ? (
                        <Spinner variant="light"></Spinner>
                      ) : (
                        <>
                          <MdFileUpload color={style.color} size={28} />
                          <animated.span
                            style={{
                              fontFamily: "Montserrat",
                              color: style.color,
                              fontWeight: fontWeights.medium,
                              fontSize: fontSizes.small,
                            }}
                          >
                            logo
                          </animated.span>
                        </>
                      )}
                    </animated.div>
                  )}
                </Spring>
              </div>
            </div>
            <span
              style={{
                fontFamily: "Montserrat",
                color: colors.lightBlue,
                fontWeight: fontWeights.bold,
                fontSize: fontSizes.medium,
              }}
            >
              Sélectionnées
            </span>
            <div
              className="mt-1"
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                alignContent: "flex-start",
                flexWrap: "wrap",
              }}
            >
              {this.state.parameterGroup?.parameters?.filter(
                (color) => color.activated
              ).length > 0
                ? this.state.parameterGroup.parameters
                    .filter((color) => color.activated)
                    .map((color) => (
                      <div>
                        <Spring
                          from={{ opacity: 0, height: 60, width: 70 }}
                          to={{
                            opacity: 1,
                            height:
                              this.state["color" + color.id] ||
                              this.state.colorSelectedId == color.id
                                ? 65
                                : 60,
                            width:
                              this.state["color" + color.id] ||
                              this.state.colorSelectedId == color.id
                                ? 75
                                : 70,
                          }}
                        >
                          {(style) => (
                            <animated.div
                              onMouseEnter={() => {
                                this.setState({ ["color" + color.id]: true });
                              }}
                              onMouseLeave={() => {
                                this.setState({ ["color" + color.id]: false });
                              }}
                              onClick={() =>
                                this.setState({
                                  colorSelectedId: color.id,
                                  addColor: false,
                                })
                              }
                              className="me-1 mb-2"
                              style={{
                                ...style,
                                cursor: "pointer",
                                borderRadius: 10,
                                backgroundColor: color.value,
                              }}
                            ></animated.div>
                          )}
                        </Spring>
                        <Slider
                          onSliderStop={this.updateColorWeight.bind(
                            this,
                            color.id
                          )}
                          height={12}
                          min={0}
                          max={3}
                          width={70}
                          defaultValue={color.weight}
                          step={1}
                        ></Slider>
                      </div>
                    ))
                : null}
              <Spring
                from={{
                  backgroundColor: "transparent",
                  color: colors.lightBlue,
                }}
                to={{
                  backgroundColor:
                    this.state["addColorHover"] || this.state.addColor
                      ? colors.lightBlue
                      : "transparent",
                  color:
                    this.state["addColorHover"] || this.state.addColor
                      ? colors.white
                      : colors.lightBlue,
                }}
              >
                {(style) => (
                  <animated.div
                    onClick={() => this.setState({ addColor: true })}
                    className="p-1 mb-2"
                    onMouseEnter={() => {
                      this.setState({ ["addColorHover"]: true });
                    }}
                    onMouseLeave={() => {
                      this.setState({ ["addColorHover"]: false });
                    }}
                    style={{
                      ...style,
                      display: "flex",
                      flexDirection: "column",
                      height: 60,
                      width: 100,
                      borderRadius: 10,
                      borderStyle: "solid",
                      borderWidth: 1,
                      borderColor: colors.lightBlue,
                      cursor: "pointer",
                      justifyContent: "space-around",
                      alignItems: "center",
                      borderBottomStyle: "solid",
                    }}
                  >
                    <>
                      <FaPlus size={20} color={style.color}></FaPlus>
                      <animated.span
                        style={{
                          fontFamily: "Montserrat",
                          color: style.color,
                          fontWeight: fontWeights.medium,
                          fontSize: fontSizes.small,
                        }}
                      >
                        Ajouter
                      </animated.span>
                    </>
                  </animated.div>
                )}
              </Spring>
            </div>
          </div>
          <div
            className="p-2 "
            style={{
              display: "flex",
              width: "50%",
              flexDirection: "column",
              alignItems: "flex-start",
              borderWidth: 1,
              minHeight: 200,
              height: "100%",
              borderColor: colors.lightBlue,
              borderStyle: "solid",
              borderRadius: 10,
              position: "relative",
            }}
          >
            {this.state.addColor ? (
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  height: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <ChromePicker
                  styles={{
                    default: {
                      picker: {
                        width: "100%",
                        backgroundColor: colors.darkBlue,
                        boxShadow: "none",
                        color: colors.white,
                      },
                    },
                  }}
                  disableAlpha={true}
                  color={this.state.colorPickerSelected}
                  onChange={(color) =>
                    this.setState({ colorPickerSelected: color.hex })
                  }
                ></ChromePicker>
                <div
                  style={{
                    width: "100%",
                    height: "100%",

                    display: "flex",
                    alignItems: "flex-end",
                    justifyContent: "flex-start",
                  }}
                >
                  <Button
                    onClick={() => this.addColor()}
                    height={40}
                    title={"Ajouter"}
                  ></Button>
                </div>
              </div>
            ) : (
              <>
                <div
                  style={{
                    position: "absolute",
                    cursor: "pointer",
                    borderRadius: 10,
                    backgroundColor: colorSelected?.value,
                    width: 70,
                    height: 60,
                    right: 5,
                    top: 5,
                  }}
                ></div>
                <div
                  className="mb-1"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    borderWidth: 1,
                  }}
                >
                  <span
                    style={{
                      fontFamily: "Montserrat",
                      color: colors.blue,
                      fontWeight: fontWeights.bold,
                      fontSize: fontSizes.medium,
                    }}
                  >
                    Nom
                  </span>
                  <span
                    style={{
                      fontFamily: "Montserrat",
                      color: colors.white,
                      fontWeight: fontWeights.medium,
                      fontSize: fontSizes.medium,
                    }}
                  >
                    {colorSelected?.name
                      ? colorSelected.name
                      : "Aucune couleur sélectionnée"}
                  </span>
                </div>
                <span
                  style={{
                    fontFamily: "Montserrat",
                    color: colors.blue,
                    fontWeight: fontWeights.bold,
                    fontSize: fontSizes.medium,
                  }}
                >
                  Description
                </span>
                <div
                  className="mb-2 m"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    borderWidth: 1,
                  }}
                >
                  <span
                    style={{
                      fontFamily: "Montserrat",
                      color: colors.white,
                      fontWeight: fontWeights.medium,
                      fontSize: fontSizes.medium,
                      textAlign: "left",
                    }}
                  >
                    {colorSelected?.description}
                  </span>
                </div>

                {colorSelected && (
                  <>
                    {colorSelected.activated && (
                      <>
                        <span
                          className="mb-1"
                          style={{
                            fontFamily: "Montserrat",
                            color: colors.blue,
                            fontWeight: fontWeights.bold,
                            fontSize: fontSizes.medium,
                          }}
                        >
                          Poids
                        </span>
                        <div
                          className="mb-2 m"
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            borderWidth: 1,
                          }}
                        >
                          <Slider
                            onSliderStop={this.updateColorWeight.bind(
                              this,
                              colorSelected?.id
                            )}
                            min={0}
                            max={3}
                            height={20}
                            width={200}
                            defaultValue={colorSelected?.weight}
                            step={1}
                          ></Slider>
                        </div>
                      </>
                    )}
                    <div
                      className="mb-1"
                      style={{
                        display: "flex",
                        width: "100%",
                        height: "100%",
                        alignItems: "flex-end",
                        justifyContent: "flex-end",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Button
                          onClick={() => this.selectColor(colorSelected)}
                          height={40}
                          bgColor={
                            colorSelected.activated
                              ? colors.blue
                              : colors.darkBlue
                          }
                          title={
                            !colorSelected.activated
                              ? "Sélectionner"
                              : "Désélectionner"
                          }
                        ></Button>
                        <MdDelete
                          onClick={() => this.deleteColor()}
                          style={{ cursor: "pointer" }}
                          className="ms-2"
                          size={24}
                          color={colors.brown}
                        ></MdDelete>
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}

class Words extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addMaterial: "",
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.parameterGroups !== this.props.parameterGroups) {
      this.load();
    }
  }

  updateParameterWeight = async (parameterGroup, parameter, weight) => {
    try {
      const data = { weight: weight };
      const updatedParameter = await updateParameter(parameter.id, data);
      this.updateParameterInParametersGroup(parameterGroup, updatedParameter);
    } catch (error) {
      console.error(`Error updating color with ID ${parameter.id}:`, error);
    }
  };

  componentDidMount() {
    this.load();
  }

  addParameter = async (parameterGroup, value) => {
    try {
      const parameter = {
        value: value,
        name: value,
        weight: 1,
        activated: false,
      };
      const newParameter = await createParameter(parameterGroup.id, parameter);

      this.updateParameterInParametersGroup(parameterGroup, newParameter);
    } catch (error) {
      console.error(
        `Error creating parameter for parameter group with ID ${parameterGroup.id}:`,
        error
      );
    }
  };

  updateParameterInParametersGroup = (parameterGroup, updatedParameter) => {
    const parameterGroupKey = `${parameterGroup.type}ParameterGroup`;
    const oldParameterGroup = this.state[parameterGroupKey];
    const oldParameters = oldParameterGroup.parameters;
    const parameterIndex = oldParameters.findIndex(
      (parameter) => parameter.id === updatedParameter.id
    );

    let newParameters;
    if (parameterIndex !== -1) {
      // Replace the parameter if it exists in the list
      newParameters = oldParameters.map((parameter, index) => {
        if (index === parameterIndex) {
          return updatedParameter;
        }
        return parameter;
      });
    } else {
      // Add the parameter if it doesn't exist in the list
      newParameters = [...oldParameters, updatedParameter];
    }

    const updatedParameterGroup = {
      ...oldParameterGroup,
      parameters: newParameters,
    };

    this.setState({
      [parameterGroupKey]: updatedParameterGroup,
    });
  };

  deleteParameter = async (parameterGroup, parameterId) => {
    try {
      await deleteParameter(parameterId);
      this.removeParameterFromParametersGroup(parameterGroup, parameterId);
    } catch (error) {
      console.error(`Error deleting parameter with ID ${parameterId}:`, error);
    }
  };

  removeParameterFromParametersGroup = (parameterGroup, parameterId) => {
    const parameterGroupKey = `${parameterGroup.type}ParameterGroup`;
    const oldParameterGroup = this.state[parameterGroupKey];
    const oldParameters = oldParameterGroup.parameters;
    const newParameters = oldParameters.filter(
      (parameter) => parameter.id !== parameterId
    );

    const updatedParameterGroup = {
      ...oldParameterGroup,
      parameters: newParameters,
    };

    this.setState({
      [parameterGroupKey]: updatedParameterGroup,
    });
  };

  selectParameter = async (parameterGroup, parameter) => {
    try {
      const data = { activated: !parameter.activated };
      const updatedParameter = await updateParameter(parameter.id, data);
      this.updateParameterInParametersGroup(parameterGroup, updatedParameter);
    } catch (error) {
      console.error(`Error updating parameter with ID ${parameter.id}:`, error);
    }
  };

  load() {
    if (!this.props.parameterGroups) return;
    const materialsParameterGroup = this.props.parameterGroups.find(
      (parameterGroup) => parameterGroup && parameterGroup.type === "materials"
    );
    if (
      materialsParameterGroup &&
      Array.isArray(materialsParameterGroup.parameters)
    ) {
      materialsParameterGroup.parameters.sort((a, b) => a.id - b.id);
    }
    const styleParameterGroup = this.props.parameterGroups.find(
      (parameterGroup) => parameterGroup && parameterGroup.type === "style"
    );
    if (styleParameterGroup && Array.isArray(styleParameterGroup.parameters)) {
      styleParameterGroup.parameters.sort((a, b) => a.id - b.id);
    }
    const adjectivesParameterGroup = this.props.parameterGroups.find(
      (parameterGroup) => parameterGroup && parameterGroup.type === "adjectives"
    );
    if (
      adjectivesParameterGroup &&
      Array.isArray(adjectivesParameterGroup.parameters)
    ) {
      adjectivesParameterGroup.parameters.sort((a, b) => a.id - b.id);
    }
    const lightingParameterGroup = this.props.parameterGroups.find(
      (parameterGroup) => parameterGroup && parameterGroup.type === "lighting"
    );
    if (
      lightingParameterGroup &&
      Array.isArray(lightingParameterGroup.parameters)
    ) {
      lightingParameterGroup.parameters.sort((a, b) => a.id - b.id);
    }
    this.setState({
      materialsParameterGroup,
      styleParameterGroup,
      adjectivesParameterGroup,
      lightingParameterGroup,
    });
  }

  async generateParameterGroup(parameterGroup, generationLoading) {
    try {
      this.setState({ [generationLoading]: true });
      const response = await generateColors(parameterGroup.id);
      response.parameters.sort((a, b) => a.id - b.id);
      if (parameterGroup.type === "materials") {
        this.setState({
          materialsParameterGroup: response,
          [generationLoading]: false,
        });
      }
      if (parameterGroup.type === "style") {
        this.setState({
          styleParameterGroup: response,
          [generationLoading]: false,
        });
      }
      if (parameterGroup.type === "adjectives") {
        this.setState({
          adjectivesParameterGroup: response,
          [generationLoading]: false,
        });
      }
      if (parameterGroup.type === "lighting") {
        this.setState({
          lightingParameterGroup: response,
          [generationLoading]: false,
        });
      }
    } catch (error) {
      console.error(
        `Error generating parameters for parameter group with ID ${parameterGroup.id}:`,
        error
      );
    }
  }

  renderParameterGroup = (parameterGroup, groupTitle, placeholder) => {
    const addStateVariable = `add${groupTitle.replace(/\s/g, "")}`;
    const generationLoading = `loading${groupTitle.replace(/\s/g, "")}`;
    return (
      <Col
        className="p-2"
        style={{
          display: "flex",
          flexDirection: "column",
          height: 300,

          borderWidth: 1,
          justifyContent: "space-between",
          maxHeight: 300,
          margin: 0,
          padding: 0,
        }}
        md={6}
        xs={12}
      >
        <div
          className="mb-2 "
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <span
            style={{
              fontFamily: "Montserrat",
              color: colors.lightBlue,
              fontWeight: fontWeights.bold,
              fontSize: fontSizes.medium,
            }}
          >
            {groupTitle}
          </span>
          {parameterGroup && parameterGroup.type === "materials" && (
            <span
              className="ms-2"
              style={{
                fontFamily: "Montserrat",
                fontWeight: fontWeights.bold,
                fontSize: fontSizes.medium,
              }}
            >
              <span
                style={{
                  fontFamily: "Montserrat",
                  color: colors.brown,
                }}
              >
                {
                  parameterGroup?.parameters?.filter(
                    (parameter) => parameter.activated
                  ).length
                }
              </span>
              <span
                style={{
                  fontFamily: "Montserrat",
                  color: colors.white,
                }}
              >
                /3
              </span>
            </span>
          )}
        </div>
        <div
          style={{
            display: "flex",
            width: "100%",
            height: "100%",
            flexDirection: "column",
            overflow: "auto",
          }}
        >
          <div
            className="p-1 px-2"
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "row",
              backgroundColor: colors.blue,
              borderRadius: 10,
            }}
          >
            <div
              style={{
                width: "50%",
                display: "flex",
              }}
            >
              <span
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: fontWeights.medium,
                  fontSize: fontSizes.small,
                  color: colors.white,
                }}
              >
                Dénomination
              </span>
            </div>
            <div
              style={{
                width: "48%",
                display: "flex",
              }}
            >
              <span
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: fontWeights.medium,
                  fontSize: fontSizes.small,
                  color: colors.white,
                }}
              >
                Poids
              </span>
            </div>
          </div>
          {parameterGroup &&
            parameterGroup.parameters.map((parameter, index) => (
              <Spring
                to={{
                  backgroundColor:
                    parameter.activated ||
                    this.state[parameter.id + "parameter"]
                      ? colors.brown
                      : "transparent",
                  color: colors.white,
                  borderColor:
                    parameter.activated ||
                    this.state[parameter.id + "parameter"]
                      ? colors.brown
                      : colors.white,
                }}
              >
                {(style) => (
                  <div
                    className="mt-2"
                    key={index}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        width: "48%",
                        display: "flex",
                      }}
                    >
                      <animated.div
                        onMouseEnter={() =>
                          this.setState({ [parameter.id + "parameter"]: true })
                        }
                        onMouseLeave={() =>
                          this.setState({ [parameter.id + "parameter"]: false })
                        }
                        onClick={() =>
                          this.selectParameter(parameterGroup, parameter)
                        }
                        className="px-2"
                        style={{
                          ...style,
                          cursor: "pointer",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: 10,
                          borderWidth: 1,
                          borderStyle: "solid",
                        }}
                      >
                        <span
                          style={{
                            fontFamily: "Montserrat",
                            fontWeight: parameter.activated
                              ? fontWeights.bold
                              : fontWeights.medium,
                            fontSize: fontSizes.medium,
                            color: colors.white,
                            textAlign: "left",
                          }}
                        >
                          {parameter.name}
                        </span>
                      </animated.div>
                    </div>
                    {parameter.activated && (
                      <div
                        style={{
                          width: "50%",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Slider
                          onSliderStop={this.updateParameterWeight.bind(
                            this,
                            this.state[`${parameterGroup.type}ParameterGroup`],
                            parameter
                          )}
                          height={20}
                          min={0}
                          max={3}
                          width={100}
                          defaultValue={parameter.weight}
                          step={1}
                        ></Slider>
                      </div>
                    )}
                    <div
                      onClick={() =>
                        this.deleteParameter(parameterGroup, parameter.id)
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <MdDelete size={20} color={colors.brown}></MdDelete>
                    </div>
                  </div>
                )}
              </Spring>
            ))}
        </div>
        <div
          className="mt-2"
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              width: "40%",
            }}
          >
            <FaPlus
              className="me-2"
              size={fontSizes.small}
              color={colors.brown}
            ></FaPlus>
            <TextInput
              onKeyPress={async (e) => {
                if (e.key === "Enter") {
                  await this.addParameter(
                    parameterGroup,
                    this.state[addStateVariable]
                  );
                  this.setState({ [addStateVariable]: "" });
                }
              }}
              onChange={(e) =>
                this.setState({ [addStateVariable]: e.target.value })
              }
              value={this.state[addStateVariable]}
              placeholder={placeholder}
            ></TextInput>
          </div>
          <Spring
            from={{
              backgroundColor: "transparent",
              color: colors.lightBlue,
            }}
            to={{
              backgroundColor: this.state[generationLoading + "hover"]
                ? colors.lightBlue
                : "transparent",
              color: this.state[generationLoading + "hover"]
                ? colors.white
                : colors.lightBlue,
            }}
          >
            {(style) => (
              <animated.div
                onClick={() =>
                  !this.state[generationLoading] &&
                  this.generateParameterGroup(parameterGroup, generationLoading)
                }
                onMouseEnter={() =>
                  this.setState({ [generationLoading + "hover"]: true })
                }
                onMouseLeave={() =>
                  this.setState({ [generationLoading + "hover"]: false })
                }
                className="ms-2 me-1 mb-2"
                style={{
                  ...style,
                  display: "flex",
                  flexDirection: "column",
                  height: 40,
                  width: 60,
                  borderRadius: 10,
                  borderStyle: "solid",
                  borderWidth: 1,
                  borderColor: colors.lightBlue,
                  cursor: "pointer",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {this.state[generationLoading] ? (
                  <Spinner variant="light"></Spinner>
                ) : (
                  <>
                    <animated.span
                      style={{
                        fontFamily: "Montserrat",
                        color: style.color,
                        fontWeight: fontWeights.black,
                        fontSize: fontSizes.large,
                      }}
                    >
                      IA
                    </animated.span>
                  </>
                )}
              </animated.div>
            )}
          </Spring>
        </div>
      </Col>
    );
  };

  render() {
    return (
      <div
        className="mt-4"
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          alignItems: "flex-start",
        }}
      >
        <span
          style={{
            fontFamily: "Montserrat",
            color: colors.white,
            fontWeight: fontWeights.bold,
            fontSize: fontSizes.large,
          }}
        >
          Mots-clés
        </span>
        <div
          className="mt-2"
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            alignItems: "flex-start",
          }}
        >
          <Row
            gutter={16}
            className="mb-3"
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              padding: 0,
              margin: 0,
            }}
          >
            {this.renderParameterGroup(
              this.state.materialsParameterGroup,
              "Matériaux",
              "Matériau"
            )}
            {this.renderParameterGroup(
              this.state.adjectivesParameterGroup,
              "Adjectifs de style",
              "Adjectif"
            )}
          </Row>
          <Row
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              padding: 0,
              margin: 0,
            }}
          >
            {this.renderParameterGroup(
              this.state.styleParameterGroup,
              "Style",
              "Style"
            )}
            {this.renderParameterGroup(
              this.state.lightingParameterGroup,
              "Lumière",
              "Lumière"
            )}
          </Row>
        </div>
      </div>
    );
  }
}

class GeneralIdentity extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newProjectName: this.props.project?.name,
      parameterGroups: [],
    };
  }

  getParameterGroups = async (projectId) => {
    try {
      const response = await getParameterGroups(this.props.project.id);
      this.setState({ parameterGroups: response });
    } catch (error) {
      console.error(
        `Error getting parameter groups for project with ID ${projectId}:`,
        error
      );
      throw error;
    }
  };

  componentDidMount() {
    this.getParameterGroups(this.props.project.id);
  }

  renderColors = (parameterGroups) => {
    const colors = parameterGroups.find(
      (parameterGroup) => parameterGroup.type === "colors"
    );

    return <Colors parameterGroup={colors}></Colors>;
  };

  renderWords = (parameterGroups) => {
    const wordsParameterGroups = parameterGroups.filter(
      (parameterGroup) => parameterGroup.type != "colors"
    );

    return <Words parameterGroups={wordsParameterGroups}></Words>;
  };

  render() {
    return (
      <div
        className="p-3"
        style={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          overflow: "auto",
        }}
      >
        <div className="mb-3">
          <span
            style={{
              color: colors.brown,
              fontSize: fontSizes.large,
              fontFamily: "Montserrat",
              fontWeight: fontWeights.bold,
            }}
          >
            Identité générale
          </span>
        </div>
        <div
          style={{
            display: "flex",
            height: "100%",
            width: "100%",
            flexDirection: "column",
            justifyContent: "flex-start",
          }}
        >
          <Keywords project={this.props.project}></Keywords>
          {this.renderColors(this.state.parameterGroups)}
          {this.renderWords(this.state.parameterGroups)}
        </div>
      </div>
    );
  }
}

export default GeneralIdentity;
